<template>
    <v-row>
        <v-col cols="4" sm="5" class="hidden-md-and-up mt-10 ml-4">
          <v-app-bar-nav-icon @click="drawer = true"
            class="d-flex d-lg-none">
          </v-app-bar-nav-icon>
          <v-navigation-drawer v-model="drawer" absolute temporary>
              <v-list nav dense>
                <v-list-item-group>
                  <v-list-item v-if="isUserLoggedIn()">
                    <v-row>
                      <v-col cols="4">
                      </v-col>
                      <v-col>
                         <v-avatar size="40">
                              <v-img :src="imagePreview"></v-img>
                          </v-avatar>
                      </v-col>
                    </v-row>
                  </v-list-item>
                  <v-list-item v-if="isUserLoggedIn()">
                    <v-row>
                      <v-col cols="12">
                         <h5>Hi {{getStudent().firstName}}</h5>
                      </v-col>
                    </v-row>
                  </v-list-item>
                  <v-list-item v-if="isUserLoggedIn()"> 
                    <v-row>
                      <v-col>
                        <router-link to="/account" tag="span">
                          <v-row>
                            <v-col>
                              <h5 v-if="amount != null">
                                  Je hebt €{{parseFloat(amount).toFixed(2)}} in je wallet
                                </h5>
                            </v-col>         
                          </v-row>
                        </router-link>
                      </v-col>
                    </v-row>
                  </v-list-item>
                  <v-divider v-if="isUserLoggedIn()" class="my-4 mx-2"></v-divider>
                  <v-list-item v-for="item in menuMobile" :key='item.text' :to="item.path" class="menu"> 
                   <v-list-item-title v-if="item.index===7 && getNumberOfUnreadMessages()>0" class="mt-n3">
                      {{item.text}}
                      <v-badge
                        :content="getNumberOfUnreadMessages()"
                        color="primary"
                        style="padding-left:12px; padding-bottom:10px"
                        overlap>
                      </v-badge>
                    </v-list-item-title>
                    <v-list-item-title v-if="item.index===7 && getNumberOfUnreadMessages()===0">
                      {{item.text}}
                    </v-list-item-title>
                    <v-list-item-title v-if="item.index!==7">{{item.text}}</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="isUserLoggedIn()" @click="handleMenuClick()">
                    <v-list-item-content>
                        <v-list-item-title>
                          Logout
                        </v-list-item-title>
                      </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
          </v-navigation-drawer>
        </v-col>
        <v-col cols="4" md="2" sm="4" lg="1">
          <v-col class="mt-3">
              <router-link to="/">
                <v-img src="../assets/ico/boekshare_100x120px.svg"
                  min-height="70"
                  max-height="70"
                  max-width="60">
                </v-img>
              </router-link>
          </v-col>
        </v-col>
        <v-col class="hidden-md-and-up mt-6 ml-n4">
          <v-row justify="end" class="mr-4">
            <v-col cols="5" sm="5" md="2">
              <v-btn icon class="mt-4">
                <router-link to="/shoppingcart" tag="span">
                    <v-badge
                      color="green"
                      :value="numberOfCartItems"
                      :content="numberOfCartItems">
                      <v-icon>
                        mdi-cart
                      </v-icon> 
                    </v-badge>      
                </router-link>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="11" sm="12" md="6" lg="7" class="ml-4 mx-6 mt-md-8" style="min-width:300px; max-width:1200px">
          <v-autocomplete
              id="navigationSearchFieldId"
              clearable
              v-model="modelSelectedBook"
              :items="items"
              :loading="loading"
              :search-input.sync="search"
              @keyup="inputChanged"
              color="primary"
              hide-no-data
              clear-on-select
              item-text="desc"
              item-value="id"
              label="Zoek op titel, ISBN"
              prepend-icon="mdi-magnify"
              return-object 
            >
              <template v-if="item" slot="item" slot-scope="{ item }">
                <v-list-item-content :id="item.isbn">
                  <p class="font-weight-bold">{{item.title}}</p>
                  <p class="font-weight-thin">{{item.isbn}} | {{item.edition}}</p>
                </v-list-item-content>
              </template>
          </v-autocomplete>
        </v-col>
        <v-col class="mt-12 hidden-md-and-down" cols="1">
          <v-btn
            outlined
            text
            @click="onJobsClicked()"
            color="primary">
            {{getPageSection()}}
            <v-icon class="ml-2" v-if="getPageSection() === 'JOBS'">
              mdi-briefcase-variant-outline
            </v-icon>
            <v-icon class="ml-2" v-if="getPageSection() === 'BOOKS'">
              mdi-book-open-page-variant-outline
            </v-icon>
          </v-btn>
        </v-col>
        <v-col class="hidden-sm-and-down" cols="2">
          <v-card-actions class="mt-6">
            <v-col class="mt-1">
              <v-dialog
                max-width="600"
                transition="dialog-top-transition"
                v-model="dialogLogin"
                 id="loginDialogId"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    id="loginButtonId"
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    outlined
                    @click="onLoginClicked()"
                    v-show="!showLoggedInIcon">
                    LOG IN
                  </v-btn>
                </template>
                <template class="ma-0">
                  <v-card>
                    <Login id="navlogindialogid" @closeDialogs="closeDialogs" @registerClicked="registerClicked"></Login>
                  </v-card>
                </template>
              </v-dialog>
            </v-col>
            <v-col class="mt-1">
               <v-btn
                  id="registerButtonId"
                  color="primary"
                  @click="onSignUpClicked()"
                  v-show="!showLoggedInIcon">
                  SIGN UP
                </v-btn>
            </v-col>
            <v-col class="mt-n4">
              <v-card-actions>
                <v-col class="mr-3">
                  <v-btn icon v-show="showLoggedInIcon" id="shoppingCartButtonId">
                    <router-link to="/shoppingcart" tag="span">
                      <v-badge
                        color="green"
                        :value="numberOfCartItems"
                        :content="numberOfCartItems">
                        <v-icon large>
                          mdi-cart
                        </v-icon> 
                      </v-badge>      
                    </router-link>
                  </v-btn>
                </v-col>
                <v-col class="mr-3 mt-n1">
                  <v-btn
                    icon
                    v-show="showLoggedInIcon">
                    <router-link to="/account" tag="span">
                       <h4 id="moneyInWalletId">€{{parseFloat(amount).toFixed(2)}}</h4>
                    </router-link>
                  </v-btn>
                </v-col>
                <v-col class="mt-n2">
                  <v-btn icon
                    id="userAccountAvatarId"
                    v-show="showLoggedInIcon">
                    <router-link to="/account" tag="span">
                      <v-badge
                          v-if="getNumberOfUnreadMessages()>0"
                          :content="getNumberOfUnreadMessages()"
                          color="primary"
                          overlap>
                        <v-avatar size="40">
                          <v-img v-if="!getStudent().photo" :src="imagePreview" max-height="80"></v-img>
                          <v-img v-if="getStudent().photo" :src="imagePreview"></v-img>
                        </v-avatar>
                      </v-badge>
                      <v-avatar size="40" v-if="getNumberOfUnreadMessages()===0">                       
                        <v-img :src="getStudent().photo ? getStudent().photo : imagePreview" max-height="80"></v-img>
                      </v-avatar>
                    </router-link>
                  </v-btn>
                </v-col>
              </v-card-actions>
            </v-col>
          </v-card-actions>
        </v-col>
    </v-row>
</template>

<script src="https://unpkg.com/vue/dist/vue.js"></script>
<script src="https://unpkg.com/vue-router/dist/vue-router.js"></script>

<script>
import Login from "./Login.vue";
import CreateAccount from "./CreateAccount.vue";
import { mapActions, mapGetters, mapState } from 'vuex';
import PublishedBookService from "../services/PublishedBookService"
import NoUserImage from "../assets/img/no-user.png"

export default {
    name: 'Nav',
    data: () => ({
      dialogLogin: false,
      dialogRegister: false,
      amount: 0.0,
      showLoggedInIcon: false,
      searchedCriteria: null,
      search: null,
      entries: [],
      descriptionLimit: 60,
      numberOfCartItems:0,
      page: 1,
      loading: false,
      drawer: false,
      selectMenuOption: 'Ruilen',
      searchOptions: [
            'Ruilen', 'Boek Uploaden', 'Lease',
      ],
      modelSelectedBook: null,
      menuMobile: [],
      imagePreview: NoUserImage,
      menuItems: [
      { title: 'Home', path: '/' },
      { title: 'Account', path: '/account' }]
    }),
    components: {
      Login,
      CreateAccount
    },
    created(){
      this.loadStudent();
      this.loadMenuItemsForMobile();
    },
    methods: {
      ...mapGetters(['getMoney']),
      ...mapGetters(['isUserLoggedIn']),
      ...mapGetters(['getCart']),
      ...mapActions(['saveNavigationSearchValue']),
      ...mapGetters(['getStudent']),
      ...mapGetters(['isAdmin']),
      ...mapActions(['logout']),
      ...mapActions(['resetNavigationSearchValue']),
      ...mapGetters(['getNumberOfUnreadMessages']),
      ...mapActions(['saveStateOfPublishedBookOverview']),
      ...mapGetters(['getPageSection']),
      inputChanged(event) {
        if (event.code == "Enter"){
          if(this.modelSelectedBook != null && 
              this.search != null){
              this.entries = [];
              this.resetNavigationSearchValue();
              this.$router.push({name: 'PublishedBooksOverview', params: {searchCriteria: this.searchedCriteria}});
              this.search = null;
          }
        }
        else if (event.code == "Backspace"){
          if(this.$router.currentRoute.path !== '/'){
            this.$router.push('/');
            this.entries = [];
            this.items = null;
          }
        }
      },

      onJobsClicked(){
        if(this.getPageSection() === 'JOBS'){
          this.$router.push({name:'JobsOverview'});
        } else {
          this.$router.push({name:'Home'});
        }
      },

      loadMenuItemsForMobile(){
        if(this.isUserLoggedIn()){
            this.menuMobile = [
            { index:1, text: 'Account', path: '/Account' },
            { index:2, text: 'Mijn boeken', path: '/MyBooks' },
            { index:3, text: 'Mijn aankopen', path: '/Purchases' },
            { index:4, text: 'Mijn verlanglijstje', path: '/MyPublishedBookAlerts' },
            { index:5, text: 'Verkocht', path: '/SoldBooks' },
            { index:6, text: 'Mijn cashouts', path: '/MyCashouts' },
            { index:7, text: 'Mijn berichten', path: '/MyConversations' },
            { index:8, text: 'Jobs', path: '/JobsOverview' },
            { index:9, text: 'Reviews', path: '/ReviewsOverview' },
          ];
          
          if(this.isUserLoggedIn() && this.isAdmin()){
            this.menuMobile.push({ index:11, text: 'Published Book*', path: '/admin/publishedbooks/addorupdate' });
            this.menuMobile.push({ index:12, text: 'Users*', path: '/admin/students/studentsoverview' });
            this.menuMobile.push({ index:13, text: 'Discounts*', path: '/admin/discounts/discountsoverview' });
            this.menuMobile.push({ index:14, text: 'Cashouts*', path: '/admin/cashouts/cashouts' });
            this.menuMobile.push({ index:15, text: 'Shipments*', path: '/admin/shipments/shipments' });
            this.menuMobile.push({ index:16, text: 'Checkouts*', path: '/admin/checkouts/checkoutsoverview' });
            this.menuMobile.push({ index:17, text: 'Statistieken*', path: '/admin/statistics/statisticsoverview' });
            this.menuMobile.push({ index:18, text: 'Conversaties*', path: '/admin/conversations/conversationsoverview' });
            this.menuMobile.push({ index:19, text: 'Advertisements*', path: '/admin/advertisements/advertisementsoverview' });
            this.menuMobile.push({ index:20, text: 'Intervenes*', path: '/admin/intervene/intervenesoverview' });
            this.menuMobile.push({ index:20, text: 'Intervene Statistics*', path: '/admin/intervene/intervenestatistics' });
            this.menuMobile.push({ index:21, text: 'Jobs*', path: '/admin/jobs/jobsadminoverview' });
          }
        } else{
           this.menuMobile = [
            { text: 'Login', path: '/login' },
            { text: 'Registreren', path: '/registerstudent' },
            { text: 'Jobs', path: '/JobsOverview' },
          ];
        }

      },
      onSignUpClicked(){
        this.gtag_report_conversion();
        this.$gtag.event("click-register", {
        'event_category': "registration",
        'event_label': "Use clicked the register button",
        'value': 1
        });

        this.$router.push({name: 'RegisterStudent'});
      },
      gtag_report_conversion(url) {
            var callback = function () {
                if (typeof(url) != 'undefined') {
                    window.location = url;
                }
            };
            gtag('event', 'conversion', {
                'send_to': 'AW-10926706475/iUYICNXvrsIDEKueodoo',
                'event_callback': callback
            });
            return false;
      },
      handleMenuClick(){
          this.logout();
          this.$router.push({name: '/'});
          this.search = null;
          this.$router.go();
      },
      onLoginClicked(){
          this.$gtag.event("click-login", {
          'event_category': "login",
          'event_label': "Use clicked the login button",
          'value': 1
          });
      },
      
      closeDialogs(){
         this.dialogLogin = false;
         this.dialogRegister = false;
      },

      loadStudent(){
        if(this.isUserLoggedIn()) {
          this.showLoggedInIcon = true;
          let student = this.getStudent();
          this.amount = student.wallet.amount;

          if(student.photo !== null){
            this.imagePreview = student.photo;
          } else{
            this.imagePreview = NoUserImage;
          } 
       }
       this.numberOfCartItems = this.getCart().length;
      },

      registerClicked(){
        this.closeDialogs();
        this.dialogRegister = true;
      },

      handleCanceledRegistration(){
        this.closeDialogs();
      },

      handleSuccess(){
        this.closeDialogs();
      }
     },
     computed: {
        ...mapState(['loggedIn']),
        ...mapState(['money']),
        ...mapState(['shoppingCart']),
        ...mapState(['numberOfUnreadMessages']),
        items () {
          return this.entries.map(entry => { 
            const desc = entry.title + ', Druk: ' + entry.edition + ', ISBN: ' + entry.isbn;
            return Object.assign({}, entry, {desc});
        })
      },
     },
    watch: {
      ...mapState(['student']),
      numberOfUnreadMessages(newValue, oldValue) {
        if (newValue) {
          this.unreadMessages = newValue;
        }
      },

      async search (val) {
        this.searchedCriteria = val;
        
        if(this.search != null && this.search.length <= 2) {
          this.$router.push('/');
        }

        if (!val || val.length <= 2){
          this.loading = false;
          this.entries = []; 
          this.modelSelectedBook = null;
          return;
        } 
        
        if (this.loading) {
          this.entries = [];
          this.modelSelectedBook = null;
          return;
        }

        this.$gtag.event("search-book", {
          'event_category': "search",
          'event_label': "User searched for a book.",
          'value': val
          });

        this.loading = true;  
        const booksResponse = await PublishedBookService.searchPublishedBook(1, 20, val);
        if(booksResponse !== null){
          this.entries = booksResponse.books;
        }
        this.loading = false;
      },

      money(newValue, oldValue) {
        this.amount = newValue;
      },

      modelSelectedBook(newValue, oldValue) {
        if(newValue){
          this.saveNavigationSearchValue(newValue);
          this.saveStateOfPublishedBookOverview({publishedBook:newValue});
          this.$router.push({name: 'PublishedBookOverview', query:{publishedbook:newValue.id}});
          this.search = null;
        }
      },

      imagePreview(newValue, oldValue) {},

      loggedIn(newValue, oldValue) {
        if (newValue) {
          this.closeDialogs();
          this.showLoggedInIcon = true;
          this.loadMenuItemsForMobile();
        }
      },

      shoppingCart(newValue, oldValue){
        if (newValue) {
          this.numberOfCartItems = newValue.length;
        }
      },

      student(newValue, oldValue) {
        if (newValue) {
          this.closeDialogs();
                    
          if(newValue.photo){
              this.imagePreview = newValue.photo;
          } else{
              this.imagePreview = NoUserImage;
          } 
        }
      },
     },
  };
</script>

<style scoped>
.nav-bar {
  background-color: #F5F8FD !important;
}
.v-text-field--outlined fieldset {
  border-color: #644FAB;
}
.menu:hover {
  color: white !important;
  background-color: #77EDCE !important;
}
.menu:active {
  color: white !important;
  background-color: #77EDCE !important;
}
.menu[aria-selected="true"] {
  color: white !important;
  background-color: #77EDCE !important;
}
.search-field {
  font-weight: bold;
}
</style>