<template>
    <v-container>
        <v-row>
            <v-col cols="3" class="hidden-sm-and-down pt-0">
                <UserMenuCard></UserMenuCard>
            </v-col>
            <v-col md="9" cols="12">
                <v-card class="mt-n3" flat v-if="(!checkouts || checkouts.length === 0) && !loading">
                     <v-card-text v-if="!errorMessage">
                           Je hebt nog geen boeken verkocht.
                     </v-card-text>
                      <v-card-text v-if="errorMessage">
                           {{errorMessage}}
                     </v-card-text>
                </v-card>
                <v-card flat v-if="loading" class="mt-3">
                    <v-row>
                        <v-col cols="6">
                        </v-col>
                        <v-col>
                            <v-progress-circular
                            indeterminate
                            color="primary">
                            </v-progress-circular>
                        </v-col>
                    </v-row>
                </v-card>
                <v-row>
                    <v-col class="ml-md-3">
                        <v-card flat class="mb-8" v-for="(checkout, index) in checkouts" :key="index">
                            <v-row style="background-color: #77EDCE">
                                <v-col cols="4">
                                    <v-card-text>Checkout ID: <span :id="'checkoutId' + checkout.id">{{checkout.id}}</span></v-card-text>
                                </v-col>
                                <v-col cols="4">
                                    <v-card-text>Gekocht op: <span :id="'checkoutTime' + checkout.id">{{getFriendlyTime(checkout.time)}}</span></v-card-text>
                                </v-col>
                                <v-col cols="3">
                                    <v-card-text>Totaal (€): <span :id="'checkoutGrandTotal' + checkout.id">{{calculateGrandTotal(checkout).toFixed(2)}}</span></v-card-text>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-card min-width="100%" v-if="!checkout.transactions || checkout.transactions.length === 0">
                                    <v-row class="pa-4">
                                        <v-card-text>Er is helaas iets misgegeaan met deze betaling.</v-card-text>
                                    </v-row>
                                </v-card>
                                <v-card flat v-if="checkout.transactions && checkout.transactions.length > 0" width="100%">
                                    <v-card flat min-width="100%" outlined
                                    v-for="(transaction, transactionIndex) in checkout.transactions" :key="transactionIndex">
                                        <v-row>
                                            <v-col cols="6" class="pb-0"> 
                                                <v-card-text class="pb-0">
                                                    Status transactie:
                                                </v-card-text>
                                            </v-col>   
                                            <v-col class="pb-0"> 
                                                 <v-card-text class="pb-0" :id="'checkoutSoldStatus' + transaction.id">
                                                    {{friendlyNameForTransactionState(transaction.state)}}
                                                </v-card-text>
                                            </v-col>   
                                        </v-row>
                                        <v-row class="py-0">
                                            <v-col cols="6" class="py-0">
                                                <v-card-text class="py-0">
                                                    Koper:
                                                </v-card-text>
                                            </v-col>
                                            <v-col class="py-0">
                                                <v-card-text class="py-0" :id="'transactionSellerName' + transaction.id">
                                                    {{transaction.buyer.firstName}} {{transaction.buyer.lastName}}
                                                </v-card-text>
                                            </v-col>
                                            <v-col cols="12" md="4" class="py-0">
                                                <div v-for="(shipment, shipmentIndex) in checkout.shipments" :key="shipmentIndex">
                                                    <v-card-text class="py-0" :id="'checkoutSoldShipment' + transaction.id" v-for="(shipmentLabel, shipmentLabelIndex) in shipment.labels" :key="shipmentLabelIndex">
                                                        <a :href="'data:application/pdf;base64,'+shipmentLabel.content" download="verzendbrief.pdf">
                                                        download verzend label
                                                        </a>
                                                    </v-card-text>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-row class="py-0">
                                            <v-col cols="6" class="py-0">
                                                <v-card-text class="py-0">
                                                    Boek:
                                                </v-card-text>
                                            </v-col>
                                            <v-col class="py-0">
                                                <v-card-text class="py-0" :id="'transactionPublishedBookTitle' + transaction.id">
                                                    {{transaction.book.publishedBook.title}}
                                                </v-card-text>
                                            </v-col>
                                        </v-row>
                                        <v-row class="py-0">
                                            <v-col cols="6" class="py-0">
                                                <v-card-text class="py-0">
                                                    ISBN:
                                                </v-card-text>
                                            </v-col>
                                            <v-col class="py-0">
                                                <v-card-text class="py-0" :id="'transactionPublishedBookIsbn' + transaction.id">
                                                    {{transaction.book.publishedBook.isbn}}
                                                </v-card-text>
                                            </v-col>
                                        </v-row>   
                                        <v-row class="py-0">
                                            <v-col cols="6" class="py-0">
                                                <v-card-text class="py-0">
                                                    Bedrag:
                                                </v-card-text>
                                            </v-col>
                                            <v-col class="py-0">
                                                <v-card-text class="py-0" :id="'transactionBookPrice' + transaction.id">
                                                    €{{parseFloat(transaction.book.price).toFixed(2)}}
                                                </v-card-text>
                                            </v-col>
                                        </v-row>
                                        <v-row class="py-0" v-if="checkout.appliedDiscounts != null && checkout.appliedDiscounts.length >0">
                                            <v-col cols="6" class="py-0">
                                                <v-card-text class="py-0">
                                                    Korting:
                                                </v-card-text>
                                            </v-col>
                                            <v-col class="py-0">
                                                <v-card-text class="py-0">
                                                    €{{checkout.appliedDiscounts[0].amount}}
                                                </v-card-text>
                                            </v-col>
                                        </v-row>                                              
                                        <v-row v-if="checkout.payment" class="py-0">
                                            <v-col cols="12" class="py-0">
                                                <v-card-text class="pb-0">
                                                    <div class="font-weight-bold">Betaling</div>
                                                </v-card-text>
                                            </v-col>
                                            <v-col cols="6" class="py-0">
                                                <v-card-text class="py-0">
                                                    Status:<br/>
                                                    Datum:<br/>
                                                </v-card-text>
                                            </v-col>
                                            <v-col cols="4" class="py-0">
                                                <v-card-text class="py-0">
                                                    <span :id="'publishedBookPaymentStatus' + transaction.id">{{getPaymentStatus(checkout.payment.status)}}</span><br/>
                                                    <span>{{getFriendlyTime(checkout.payment.createdAt)}}</span><br/>
                                                </v-card-text>
                                            </v-col>
                                        </v-row>
                                        <v-row v-for="(shipment, index) in checkout.shipments" :key="index" class="py-0">
                                            <v-col cols="12" v-if="shipment.status && shipment.status.length > 0" class="py-0">
                                                <v-card-text class="pb-0">
                                                    <div class="font-weight-bold">Verzending</div>
                                                </v-card-text>
                                            </v-col>
                                            <v-col class="py-0" cols="4" md="6" v-if="shipment.status && shipment.status.length > 0">
                                                <v-card-text class="py-0">
                                                    Status:<br/>
                                                    Datum:<br/>
                                                    Barcode:<br/>
                                                    Track and Trace:<br/>
                                                </v-card-text>
                                            </v-col>
                                            <v-col class="pt-0">
                                                <v-card-text class="pt-0" v-if="shipment.status && shipment.status.length > 0">
                                                    <span :id="'shipmentTransactionId'+shipment.id">{{shipment.status[(shipment.status.length -1)].phaseDescription}} -
                                                        {{shipment.status[(shipment.status.length -1)].statusDescription}} 
                                                    </span><br/>
                                                    {{getFriendlyTime(shipment.status[(shipment.status.length -1)].timeStamp)}}<br/>
                                                    {{shipment.barcode}}<br/>
                                                    <div v-for="(tr, index) in shipment.transactions" :key="index">
                                                        <div v-if="tr.id === transaction.id">
                                                           <a :href="'https://jouw.postnl.nl/track-and-trace/' + shipment.barcode + '-NL-' + tr.buyer.postalAddress.postalCode" target="_blank">Track &amp; Trace</a><br/>
                                                        </div>
                                                    </div>
                                                </v-card-text>
                                            </v-col>
                                            <v-col cols="12" class="pt-0 mt-0">
                                                
                                                <v-card-text class="pt-0 mt-0" v-if=" shipment.status && shipment.status.length > 0 && 
                                                                    (transaction.state === 'requested' || transaction.state === 'finished') &&
                                                                    shipment.status[(shipment.status.length -1)].phaseCode == 1 &&
                                                                    shipment.status[(shipment.status.length -1)].statusCode == 1">
                                                    <div v-for="(tr, index) in shipment.transactions" :key="index">
                                                        <div v-if="tr.id === transaction.id">
                                                           <v-btn color="error" :id="'ikHebHetBoekNiet'+tr.id" :loading="loading && selectedTransaction === tr.id" @click="dontHaveTheBook(tr)">Ik heb het boek niet</v-btn>
                                                        </div>
                                                    </div>
                                                </v-card-text>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-card> 
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                    <v-pagination
                        style="padding-bottom: 3%; padding-top: 3%"
                        v-model="page"
                        :length="totalPages"
                        @input="next"
                        :total-visible="5">
                    </v-pagination>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import UserMenuCard from '../components/UserMenuCard.vue';
import CheckoutService from "../services/CheckoutService";
import { mapGetters } from 'vuex';
import TransactionService from '../services/TransactionService';

  export default {
    data () {
      return {
        totalPages: 0,
        pageSize: 5,
        page: 1,
        loading:false,
        errorMessage:null,
        checkouts:[],
        selectedTransaction: 0,
      }
    },
    async created() {
        await this.loadCheckouts();
    },
    methods: {
      ...mapGetters(['getAccessToken']),
        async next(p) {
            try{
                this.errorMessage = null;
                this.loading = true;
                let data = await CheckoutService.getSoldBooks(this.getAccessToken(), this.page, this.pageSize);
                this.loading = false;
                this.checkouts = data.checkouts;
                this.totalCount = data.totalCount;
                this.totalPages = Math.ceil(this.totalCount / this.pageSize);
            }
             catch{
               this.errorMessage = 'Er is een fout opgetreden';
            }

            this.loading = false;
        },

        calculateGrandTotal(checkout){
            let grandTotal = 0.0;
            checkout.transactions.forEach(tr => {
                grandTotal += tr.amount;
            });
            
            return grandTotal;
        },

        async dontHaveTheBook(transaction){
            try{
                this.errorMessage = null;
                this.loading = true;
                this.selectedTransaction = transaction.id;
                await TransactionService.rejectTransaction(this.getAccessToken(), transaction.id);
                this.loading = false;
                await this.loadCheckouts();
            }
             catch{
               this.errorMessage = 'Er is een fout opgetreden';
            }

            this.loading = false;
        },

        async loadCheckouts() {
            try{
                this.errorMessage = null;
                this.loading = true;
                let data = await CheckoutService.getSoldBooks(this.getAccessToken(), this.page, this.pageSize);  
                this.loading = false;
                this.checkouts = data.checkouts;
                this.totalCount = data.totalCount;
                this.totalPages = Math.ceil(this.totalCount / this.pageSize);
            }
            catch{
                this.errorMessage = 'Er is een fout opgetreden';
            }
            this.loading = false;
        },
        
        friendlyNameForTransactionState(transactionState){
            if(transactionState === 'requested') return "VERWERKING";
            else if(transactionState === 'finished') return "AKKOORD";
            else if(transactionState === 'rejected') return "AFGEWEZEN";
            else transactionState;
        },

        getPaymentStatus(val){
            if(val === 'finished') return 'Betaald';
            else if(val === 'paid') return 'Betaald';
            else if(val === 'expired') return 'Verlopen';
            else return val;
        },

        getFriendlyDate(val){
            var date = new Date(val);
            return date.getDate()  + "-" + (date.getMonth()+1) + "-" + date.getFullYear();
        },
        getFriendlyTime(val) {
            const date = new Date(val);
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ensure the month is 2 digits
            const year = date.getFullYear();
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');

            return `${day}-${month}-${year} ${hours}:${minutes}`;
        },
    },
    components: {
          UserMenuCard
    }
  }
</script>