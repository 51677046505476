import axios from "../plugins/axios";

const baseUrl = process.env.VUE_APP_BOOKSHARE_URL+'/students';
const baseUrlIAA = process.env.VUE_APP_IAA_BOOKSHARE_URL+'/User';

export default {
    async getStudentById(studentId) {
      let student;
      await axios.get(`${baseUrl}/${studentId}`)
              .then(response =>
                  student = response.data.student);
      return student;
    },

    async getSellersPublishedBook(publishedBookId) {
      let sellers;
      let data = { publishedBookId: publishedBookId };
      await axios.post(`${baseUrl}/sellers-of-publishedbook`,data)
      .then(response =>
        sellers = response.data.sellers);

      return sellers;
    },

    async updateStudent(token, studentToUpdate) {
      let student;
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      let updateStudentRequest={student:studentToUpdate};
      await axios.post(`${baseUrl}/update`, updateStudentRequest,config)
              .then(response =>
                  student = response.data.student);
      return student;
    },

    async updateStudentName(token, name) {
      let student;
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      let request={name:name};
      await axios.post(`${baseUrl}/update-name`, request, config)
              .then(response =>
                  student = response.data.student);
      return student;
    },

    async updateStudentSurname(token, surname) {
      let student;
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      let request={surname:surname};
      await axios.post(`${baseUrl}/update-surname`, request, config)
              .then(response =>
                  student = response.data.student);
      return student;
    },

    async sendActivationAccountEmail(email, firstName, lastName, userName) {
      let result;
      let data = { email:email, firstName:firstName,lastName:lastName, userName:userName};
      await axios.post(`${baseUrlIAA}/send-activation-account-email`, data)
              .then(response =>
                result = response.data);
      return result;
    },

    async removeAlert(token, alertId){
      let alerts;
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      let removePublishedBookAlert={alertId:alertId};
      await axios.post(`${baseUrl}/remove-published-book-alert`, removePublishedBookAlert, config)
              .then(response =>
                alerts = response.data.alerts);
      return alerts;
    },

    async createAlert(token, publishedBookId, receiveNotifications){
      let alerts;
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      let createPublishedBookAlert={publishedBookId:publishedBookId, receiveNotifications:receiveNotifications};
      await axios.post(`${baseUrl}/create-published-book-alert`, createPublishedBookAlert, config)
              .then(response =>
                alerts = response.data.alerts);
      return alerts;
    },

    async getAlerts(token, pageSize, pageNumber){
      let result;
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      
      let data = { pageNumber:pageNumber, pageSize:pageSize};
      await axios.post(`${baseUrl}/get-published-book-alerts`, data, config)
              .then(response =>
                result = response.data);
      return result;
    },

    async getStudents(token, pageNumber, pageSize){
      let result;
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      let data = { pageNumber:pageNumber, pageSize:pageSize};
      await axios.post(`${baseUrl}/admin/students`, data, config)
              .then(response =>
                result = response.data);
      return result;
    },

    async getStudentStatistics(token){
      let result;
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      let data = {};
      await axios.post(`${baseUrl}/admin/get-statistics`, data, config)
              .then(response =>
                result = response.data);
      return result;
    },

    async findStudents(token, searchText, pageNumber, pageSize){
      let result;
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      let data = { searchText:searchText, pageNumber:pageNumber, pageSize:pageSize};
      await axios.post(`${baseUrl}/admin/find-students`, data, config)
              .then(response =>
                result = response.data);
      return result;
    },

    async getBoekShareAccounts(token) {
      let result;
      const config = {
          headers: { Authorization: `Bearer ${token}` }
      };

      await axios.get(`${baseUrl}/admin/get-boekshare-accounts/`, config)
              .then(response =>
                  result = response.data);

      return result;
  },
}
