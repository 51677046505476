<template>
    <v-container>
        <v-row>
            <v-col cols="3" class="hidden-sm-and-down pt-0">
                <UserMenuCard></UserMenuCard>
            </v-col>
            <v-col class="pt-0">
                <v-card flat v-if="(!checkouts || checkouts.length === 0) && !loading">
                     <v-card-text v-if="!errorMessage">
                            Je hebt nog geen aankopen gedaan.
                     </v-card-text>
                     <v-card-text v-if="errorMessage">
                           {{errorMessage}}
                     </v-card-text>
                </v-card>
                <v-card flat v-if="loading" class="mt-3">
                    <v-row>
                        <v-col cols="6">
                        </v-col>
                        <v-col>
                            <v-progress-circular
                            indeterminate
                            color="primary">
                            </v-progress-circular>
                        </v-col>
                    </v-row>
                </v-card>
                <v-row class="mt-0">
                    <v-col class="ml-md-3" cols="12">
                        <v-card color="transparent" flat class="mb-8" v-for="(checkout, index) in checkouts" :key="index">
                            <v-row class="pt-4" style="background-color: #77EDCE" v-if="checkout.shipments !==  null && checkout.shipments.length > 0">
                                <v-col cols="12" md="6">
                                    <v-col cols="12" class="pa-0">
                                        <v-card-text class="pa-0">Checkout ID: <label :id="'checkoutId' + checkout.id">{{checkout.id}}</label></v-card-text>
                                    </v-col>
                                    <v-col cols="12" class="pa-0" v-if="parseFloat(calculateTotalDiscount(checkout.appliedDiscounts)).toFixed(2) > 0.0">
                                        <v-card-text class="pa-0">Korting: 
                                            €<label :id="'checkoutTotalDiscount' + checkout.id">{{parseFloat(calculateTotalDiscount(checkout.appliedDiscounts)).toFixed(2)}}</label></v-card-text>
                                    </v-col>
                                    <v-col cols="12" class="pa-0" v-if="parseFloat(calculateTotalAdministrationCosts(checkout)).toFixed(2) > 0.0">
                                        <v-card-text class="pa-0">Kopersbescherming: 
                                            €<label :id="'publishedBookAdministrationCost' + checkout.id">{{parseFloat(calculateTotalAdministrationCosts(checkout)).toFixed(2)}}</label></v-card-text>
                                    </v-col>
                                </v-col>
                                <v-col>
                                    <v-col cols="12" class="pa-0">
                                        <v-card-text class="pa-0">Totaal:
                                            €<label :id="'checkoutGrandTotal' + checkout.id">{{parseFloat(checkout.grandTotal).toFixed(2)}}</label></v-card-text>
                                    </v-col>
                                    <v-col cols="12" class="pa-0">
                                        <v-card-text class="pa-0">Gekocht op: <label :id="'checkoutTime' + checkout.id">{{getFriendlyTime(checkout.time)}}</label>
                                        </v-card-text>
                                    </v-col>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-row class="justify-md-end pr-2 mb-2 mt-md-3">
                                        <v-btn
                                            :id="'openInvoice' + checkout.id"
                                            color="primary"
                                            outlined
                                            :loading="loadingInvoice"
                                            small
                                            @click="downloadPdf(checkout)">
                                            Factuur
                                            Downloaden
                                        </v-btn>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row v-if="checkout.shipments !==  null && checkout.shipments.length > 0">
                                <v-card min-width="100%" v-if="!checkout.transactions || checkout.transactions.length === 0">
                                    <v-row class="pa-4">
                                        <v-card-text>Er is helaas iets misgegeaan met deze betaling.</v-card-text>
                                    </v-row>
                                </v-card>
                                <v-card flat color="transparent" v-if="checkout.transactions && checkout.transactions.length > 0" width="100%">
                                    <v-card
                                        class="mt-4"
                                        min-width="100%" 
                                        v-for="(transaction, transactionIndex) in checkout.transactions" :key="transactionIndex">
                                        <v-row class="py-4">
                                            <v-col class="py-0">
                                                <v-card-text class="py-0">
                                                    Verkoper:
                                                </v-card-text>
                                            </v-col>
                                            <v-col class="py-0">
                                                <v-card-text class="py-0" :id="'transactionSellerName' + transaction.id">
                                                    {{transaction.seller.firstName}} {{transaction.seller.lastName}}
                                                </v-card-text>
                                            </v-col>
                                        </v-row>
                                        <v-row class="py-0">
                                            <v-col class="py-0">
                                                <v-card-text class="py-0">
                                                    Boek: 
                                                </v-card-text>
                                            </v-col>
                                            <v-col class="py-0">
                                                <v-card-text class="py-0" :id="'transactionPublishedBookTitle' + transaction.id">
                                                    {{transaction.book.publishedBook.title}}
                                                </v-card-text>
                                            </v-col>
                                        </v-row>
                                        <v-row class="py-0">
                                            <v-col class="py-0">
                                                <v-card-text class="py-0">
                                                    ISBN: 
                                                </v-card-text>
                                            </v-col>
                                            <v-col class="py-0">
                                                <v-card-text class="py-0" :id="'transactionPublishedBookIsbn' + transaction.id">
                                                    {{transaction.book.publishedBook.isbn}}
                                                </v-card-text>
                                            </v-col>
                                        </v-row>
                                        <v-row class="py-0">
                                            <v-col class="py-0">
                                                <v-card-text class="py-0">
                                                    Prijs: 
                                                </v-card-text>
                                            </v-col>
                                            <v-col class="py-0">
                                                <v-card-text class="py-0" :id="'transactionBookPrice' + transaction.id">
                                                    €{{parseFloat(transaction.book.price).toFixed(2)}}
                                                </v-card-text>
                                            </v-col>
                                        </v-row>
                                        <v-row class="py-1" v-if="checkout.payment != null">
                                            <v-col class="py-0">
                                                <v-card-text class="py-0">
                                                    Status: 
                                                </v-card-text>
                                            </v-col>
                                            <v-col class="py-0">
                                                <v-card-text class="py-0" :id="'publishedBookPaymentStatus' + transaction.id">
                                                    {{getPaymentStatus(checkout.payment.status)}}
                                                </v-card-text>
                                            </v-col>
                                        </v-row>
                                        <v-row class="py-0" v-if="checkout.appliedDiscounts != null && checkout.appliedDiscounts.length >0">
                                            <v-col class="py-0">
                                                <v-card-text class="py-0">
                                                    Korting: 
                                                </v-card-text>
                                            </v-col>
                                            <v-col class="py-0">
                                                <v-card-text class="py-0" :id="'publishedBookAppliedDiscount' + checkout.id">
                                                    €{{parseFloat(calculateDiscountPerBook(transaction.book, checkout.appliedDiscounts)).toFixed(2)}}
                                                </v-card-text>
                                            </v-col>
                                        </v-row>
                                        <div class="mt-2" v-for="(shipment, indexSentStatus) in checkout.shipments" :key="indexSentStatus">
                                            <v-row class="py-0">
                                                <v-col class="py-0" v-if="shipment.status && shipment.status.length > 0 && shipment.transactions.find(x=>x.id==transaction.id)">
                                                    <v-card-text class="py-1">
                                                        Verzendstatus:
                                                    </v-card-text>
                                                </v-col>
                                                <v-col class="py-0" :id="'shipmentTransactionId'+shipment.id" v-if="shipment.status && shipment.status.length > 0 && shipment.transactions.find(x=>x.id==transaction.id)">
                                                    <v-card-text class="py-1">
                                                        {{shipment.status[(shipment.status.length -1)].phaseDescription}} - {{shipment.status[(shipment.status.length -1)].statusDescription}} 
                                                    </v-card-text>
                                                </v-col>
                                            </v-row>
                                            <v-row class="pt-0">
                                                <v-col class="pt-0" v-if="shipment.status && shipment.status.length > 0 && shipment.transactions.find(x=>x.id==transaction.id)">
                                                    <v-card-text class="py-0">
                                                        PostNL:
                                                    </v-card-text>
                                                </v-col>
                                                <v-col class="pt-0" v-if="shipment.status && shipment.status.length > 0 && shipment.transactions.find(x=>x.id==transaction.id)">
                                                    <v-card-text class="py-0">
                                                        {{shipment.barcode}} <br/>
                                                    </v-card-text>
                                                    <v-card-text class="py-0">
                                                        <a :href="'https://jouw.postnl.nl/track-and-trace/' + shipment.barcode + '-NL-' + shipment.transactions.find(x=>x.id==transaction.id).buyer.postalAddress.postalCode" target="_blank">Track &amp; Trace</a><br/>
                                                    </v-card-text>
                                                    <v-card-text class="pt-0" v-if="checkout.shipmentCosts != null && checkout.shipmentCosts.length > 0">
                                                        €{{parseFloat(checkout.shipmentCosts.find(x=> x.shipment.id===shipment.id).cost).toFixed(2)}}
                                                    </v-card-text>
                                                </v-col>
                                            </v-row>                                       
                                            <v-row class="mb-4 ml-1">
                                                <v-col
                                                    v-if="
                                                    shipment.status && 
                                                    shipment.transactions.find(x=>x.id==transaction.id) &&
                                                    shipment.status.length > 0 && 
                                                    (transaction.state === 'requested' || transaction.state === 'finished') && 
                                                    shipment.status[(shipment.status.length -1)].phaseCode == 4 &&
                                                    shipment.status[(shipment.status.length -1)].statusCode >= 11"
                                                    @click="receivedShipment(shipment.id)">
                                                    <v-btn 
                                                        :id="'confirmReceivedShipmentButtonid' + shipment.id"
                                                        color="primary" 
                                                        :loading="loading && 
                                                        loadingShipmentButton === shipment.id">Ontvangst bevestigen
                                                    </v-btn>
                                                </v-col>
                                                <v-col v-if="shipment.status && 
                                                    shipment.transactions.find(x=>x.id==transaction.id) &&
                                                    shipment.status.length > 0 && 
                                                    (transaction.state === 'requested' || transaction.state === 'finished' || transaction.state === 'confirmed') && 
                                                    (shipment.status[(shipment.status.length -1)].phaseCode == 5 ||
                                                    shipment.status[(shipment.status.length -1)].phaseCode == 4 &&
                                                    shipment.status[(shipment.status.length -1)].statusCode >= 11) &&
                                                    !transaction.review">
                                                    <v-row>
                                                        <v-col>
                                                            <v-btn
                                                                :id="'writeReviewButtonId' + shipment.id"
                                                                color="primary"
                                                                outlined
                                                                @click="selectTransactionToRview(transaction.id)">
                                                                Schrijf een review
                                                            </v-btn>
                                                            <v-dialog
                                                                max-width="600"
                                                                transition="dialog-top-transition"
                                                                v-model="showReviewDialog">
                                                                <v-card>
                                                                    <GiveReviewComponent 
                                                                        @reviewGiven="reviewGiven" 
                                                                        @closeDialog="closeDialog"
                                                                        :disableClosingDialog="false"
                                                                        :selectedTransaction="transaction.id"
                                                                    ></GiveReviewComponent>
                                                                </v-card>
                                                            </v-dialog>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-card> 
                                </v-card>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                    <v-pagination
                        style="padding-bottom: 3%; padding-top: 3%"
                        v-model="page"
                        :length="totalPages"
                        @input="next"
                        :total-visible="5">
                    </v-pagination>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import UserMenuCard from '../components/UserMenuCard.vue';
import CheckoutService from "../services/CheckoutService";
import ShipmentService from "../services/ShipmentService";
import GiveReviewComponent from "../components/GiveReviewComponent.vue"
import InvoicesService from '../services/InvoicesService'

import { EventBus } from '../services/EventBus'
import { mapGetters } from 'vuex';

  export default {
    data () {
      return {
        totalPages: 5,
        pageSize: 5,
        page: 1,
        checkouts:[],
        loading:false,
        errorMessage:null,
        totalCount:0,
        loadingShipmentButton:0,
        showReviewDialog:false,
        selectedTransaction:null,
        loadingInvoice: false
      }
    },

    async created() {
        await this.loadCheckouts();
    },
    
    methods: {
      ...mapGetters(['getAccessToken']),

        async next(p) {
            try{
                this.errorMessage = null;
                this.loading = true;
                let data = await CheckoutService.getCheckouts(this.getAccessToken(), this.page, this.pageSize);
                this.loading = false;
                this.checkouts = data.checkouts;
                this.totalCount = data.totalCount;
                this.totalPages = Math.ceil(this.totalCount / this.pageSize);
            } catch{
               this.errorMessage = 'Er is een fout opgetreden';
            }

            this.loading = false;
        },

        isDeprecatedAdministrationCost(checkout){
            return (checkout.administrationCost != null && Number(checkout.administrationCost) > 0.0);
        },

        calculateTotalDiscount(discounts){
            var totalDiscounts = 0.0;
            discounts.forEach(discount => {
                totalDiscounts += discount.amount;
            });

            return totalDiscounts;
        },

        async downloadPdf(checkout) {
            this.loadingInvoice = true;

            try {
                // Open a new window immediately to avoid popup blockers
                const newWindow = window.open('', '_blank');

                // Fetch the base64 encoded PDF from the service
                const response = await InvoicesService.getInvoice(this.getAccessToken(), checkout.id);

                // Assuming the response contains the base64 PDF in `data` field
                const base64Pdf = response.invoiceBase64EncodedPdf;

                // Decode the base64 string to binary data
                const byteCharacters = atob(base64Pdf);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);

                // Create a blob from the binary data
                const blob = new Blob([byteArray], { type: 'application/pdf' });

                // Create a URL for the blob and set it to the previously opened window
                const blobUrl = URL.createObjectURL(blob);
                newWindow.location.href = blobUrl;
            } catch (error) {
                if (newWindow) {
                    newWindow.close(); // Close the window if an error occurred
                }
                this.loadingInvoice = false;
            }

            this.loadingInvoice = false;
        },

        calculateTotalAdministrationCosts(checkout){
            var totalAdministrationCost = 0.0;
            if(this.isDeprecatedAdministrationCost(checkout)){
                totalAdministrationCost = checkout.administrationCost;
            } else {
                checkout.administrationCosts?.forEach(ac => {
                    totalAdministrationCost += Number(ac.cost);
                });
            }
    
            return totalAdministrationCost;
        },

        async loadCheckouts() {
            try{
                this.errorMessage = null;
                this.loading = true;
                let data = await CheckoutService.getCheckouts(this.getAccessToken(), this.page, this.pageSize);
                this.loading = false;
                this.checkouts = data.checkouts;
                this.totalCount = data.totalCount;
                this.totalPages = Math.ceil(this.totalCount / this.pageSize);
            } catch {
               this.errorMessage = 'Er is een fout opgetreden';
            }

            this.loading = false;
        },

        async selectTransactionToRview(transaction){
            this.selectedTransaction = transaction;
            this.showReviewDialog = true;
            await this.$nextTick();
            EventBus.$emit('loadRatings', transaction);
            EventBus.$emit('loadTransactionById', transaction);
        },

        calculateDiscountPerBook(book, appliedDiscounts){
            let appliedDiscount = appliedDiscounts[0];
            if(appliedDiscount.typeOfDiscount === 0){
                return ((book.price/100)*appliedDiscount.unit);
            } else {
                return 0.0;
            }
        },

        async receivedShipment(shipmentId) {
            try {
                this.loadingShipmentButton = shipmentId;
                this.loading = true;
                await ShipmentService.receivedShipment(this.getAccessToken(), shipmentId);
                this.loadCheckouts();
            } catch {
                this.errorMessage = 'Er is een fout opgetreden';
            }

            this.loading = false;
        },

        getFriendlyTime(val) {
            const date = new Date(val);
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ensure the month is 2 digits
            const year = date.getFullYear();
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');

            return `${day}-${month}-${year} ${hours}:${minutes}`;
        },

        getPaymentStatus(val){
            if(val === 'finished') return 'Betaald';
            else if(val === 'paid') return 'Betaald';
            else if(val === 'expired') return 'Verlopen';
            else return val;
        },

        async reviewGiven(){
            this.showReviewDialog = false;
            await this.loadCheckouts();
        },

        closeDialog(){
            this.showReviewDialog = false;
        }
    },
    components: {
          UserMenuCard,
          GiveReviewComponent
    }
  }
</script>