<template>
   <v-form>
        <v-container>
            <v-row justify="center" align="center" v-if="errorMessage || loading"> 
                <v-col cols="6" v-if="errorMessage">
                    <v-card-text>
                    {{errorMessage}}
                    </v-card-text>
                </v-col>
                <v-col class="my-8" cols="6">
                    <v-progress-circular
                    v-if="loading"
                    indeterminate
                    color="primary">
                    </v-progress-circular>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="mt-3">
                    <v-row>
                        <v-col>

                        </v-col>
                    </v-row>
                    <v-row v-if="conversations">
                        <v-card width="100%" class="ma-4" v-for="(conversation, index) in conversations" :key="index">
                            <v-card-title>{{conversation.id}}</v-card-title>
                            <v-card-subtitle>
                                Van: {{conversation.from.firstName}}, {{conversation.from.lastName}} ({{conversation.from.userName}})<br>
                                Naar: {{conversation.to.firstName}}, {{conversation.to.lastName}} ({{conversation.to.userName}})<br>
                                
                            </v-card-subtitle>
                            <v-card-subtitle>Berichten</v-card-subtitle>
                            <v-row class="mx-6 mb-2" v-for="(userMessage, indexUserMessages) in conversation.userMessages" :key="indexUserMessages">
                                <v-card-actions>
                                    {{ getFriendlyDate(userMessage.created)}} : {{getFriendlyTime(userMessage.created) }} : {{userMessage.text}}
                                </v-card-actions>
                            </v-row>
                        </v-card>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="3"></v-col>
                <v-col>
                    <v-row>
                        <v-pagination
                            v-if="totalPages >0"
                            style="padding-bottom: 3%; padding-top: 3%"
                            v-model="pageNumber"
                            :length="totalPages"
                            @input="load()"
                            :total-visible="7">
                        </v-pagination>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
   </v-form>
</template>

<script>
import ConversationService from '../../services/ConversationService';
import { mapGetters } from 'vuex';

export default {
    data () {
      return {
        loading: false,
        pageSize: 5,
        pageNumber: 1,
        totalPages:0,
        errorMessage: null,
        conversations: null, 
      }
    },
    async created(){
        await this.load()
    },
    methods:{
        ...mapGetters(['getAccessToken']),
        async load(){
            try{
                this.loading = true;
                this.conversations = '';
                let data = await ConversationService.getAdminConversations(this.getAccessToken(), this.pageNumber, this.pageSize);
                this.conversations = data.conversations;
                this.totalPages = Math.ceil(data.totalCount /this.pageSize);
                this.loading = false;
            }
            catch { 
                this.errorMessage = 'Er is iets mis gegaan.';
            }
        },
        getFriendlyDate(val){
            var date = new Date(val);
            return date.getDate()  + "-" + (date.getMonth()+1) + "-" + date.getFullYear();
        },
        getFriendlyTime(val){
            var date = new Date(val);
            return date.getHours() + ":" + String(date.getMinutes()).padStart(2, "0");
        },
    },
    components:{
    },
}
</script>