import axios from "../plugins/axios";

const baseUrl = `${process.env.VUE_APP_BOOKSHARE_URL}/books/admin/`;

export default {

    async addBook(token, userId, book) {
        if (typeof book.price === 'string') {
            if(book.price.includes(',')){
                book.price = book.price.replace(',', '.');
            }
        }
        
        let result;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let data = {
          "book":  book,
          "userId": userId
        }
        await axios.post(`${baseUrl}add-book-to-student`, data, config)
                .then(response =>
                    result = response.data);
  
        return result;
    },
}