<template>
  <v-card outlined style="padding-top: 2%">
    <v-row justify="center">
        <v-dialog v-model="dialogPicture">
            <v-card>
                <v-row justify="end" class="ma-2">
                <v-card-actions>
                    <v-btn text @click="dialogPicture = false">
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-card-actions>
                </v-row>
                <v-img
                    max-width="600"
                    v-if="selectedImage"
                    :src="selectedImage.fileNameFullPath"
                    class="mt-2"
                ></v-img>
            </v-card>
        </v-dialog>
    </v-row>
    <v-row class="mb-4">
      <v-col class="ml-md-8" cols="12" md="8">
        <v-row no-gutters v-if="hasValue(book.publishedBook.title)">
          <v-col cols="5" md="4" sm="6">
            <v-card-text class="py-0 label">Titel</v-card-text>
          </v-col>
          <v-col cols="7" md="8" sm="6">
            <v-card-text
              :id="'bookDetailsTitleId' + book.id" 
              class="py-0">{{book.publishedBook.title}}
            </v-card-text>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="hasValue(book.publishedBook.isbn)">
          <v-col class="py-0" cols="5" md="4" sm="6">
            <v-card-text class="label py-0">ISBN</v-card-text>
          </v-col>
          <v-col cols="7" md="8" sm="6" class="py-0">
            <v-card-text
              :id="'bookDetailsIsbnId' + book.id"
              class="py-0">
              {{book.publishedBook.isbn}}
            </v-card-text>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="hasValue(book.publishedBook.author)">
          <v-col cols="5" md="4" sm="6">
            <v-card-text class="py-0 label">Auteur</v-card-text>
          </v-col>
          <v-col cols="7" md="8" sm="6">
            <v-card-text 
              :id="'bookDetailsAuthorId' + book.id" 
              class="py-0">
              {{book.publishedBook.author}}
            </v-card-text>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="hasValue(book.publishedBook.edition)">
          <v-col cols="5" md="4" sm="6">
            <v-card-text class="label py-0">Druk</v-card-text>
          </v-col>
          <v-col cols="7" md="8" sm="6" class="py-0">
            <v-card-text
              :id="'bookDetailsEditionId' + book.id" 
              class="py-0">
              {{book.publishedBook.edition}}
            </v-card-text>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="hasValue(book.publishedBook.language)">
          <v-col cols="5" md="4" sm="6">
            <v-card-text class="py-0 label">Language</v-card-text>
          </v-col>
          <v-col cols="7" md="8" sm="6" class="py-0">
            <v-card-text id="bookDetailsLanguageId">{{ book.publishedBook.language }}</v-card-text>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="hasValue(book.publishedBook.purchaseDate)">
          <v-col cols="5" md="4" sm="6">
            <v-card-text class="label py-0">Purchased</v-card-text>
          </v-col>
          <v-col cols="7" md="8" sm="6" class="py-0">
            <v-card-text
            id="bookDetailsPurchaseDateId">
              {{book.publishedBook.purchaseDate | moment('dddd, MMMM Do YYYY')}}
            </v-card-text>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="hasValue(book.price)">
          <v-col cols="5" md="4" sm="6">
            <v-card-text class="label py-0">Prijs</v-card-text>
          </v-col>
          <v-col cols="7" md="8" sm="6">
            <v-card-text
              :id="'bookDetailsPriceId' + book.id" 
              class="py-0">
              €{{parseFloat(book.price).toFixed(2)}}
            </v-card-text>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="hasValue(book.publishedBook.state)">
          <v-col cols="5" md="4" sm="6">
            <v-card-text class="label py-0">State</v-card-text>
          </v-col>
          <v-col cols="7" md="8" sm="6">
            <v-card-text
              :id="'bookDetailsStateId' + book.id"
              class="py-0">
              {{book.publishedBook.state}}
            </v-card-text>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="hasValue(book.publishedBook.description)">
          <v-col cols="5" md="4" sm="6">
            <v-card-text class="label py-0">Description</v-card-text>
          </v-col>
          <v-col cols="7" md="8" sm="6">
            <v-card-text 
              :id="'bookDetailsDescriptionId' + book.id"
              class="py-0">
              {{book.publishedBook.description}}
            </v-card-text>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="hasValue(book.numberOfViews)">
          <v-col cols="5" md="4" sm="6">
            <v-card-text class="label py-0">Aantal keer bekeken</v-card-text>
          </v-col>
          <v-col cols="7" md="8" sm="6">
            <v-card-text class="py-0">{{ book.numberOfViews }}</v-card-text>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="5" md="4" sm="6">
            <v-card-text class="label py-0">Staat</v-card-text>
          </v-col>
          <v-col cols="7" md="8" sm="6">
            <v-card-text
              :id="'bookDetailsConditionId' + book.id" 
              class="py-0">
              {{getConditionName(book.condition)}}
            </v-card-text>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="5" md="4" sm="6">
            <v-card-text class="label py-0">Levering</v-card-text>
          </v-col>
          <v-col cols="7" md="8" sm="6">
            <v-card-text
              :id="'bookDetailsDeliveryOptionId' + book.id"
              class="py-0">
              {{getDeliveryOption(book.deliveryOption)}}
            </v-card-text>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="5" md="4" sm="6">
            <v-card-text class="label py-0">In de verkoop</v-card-text>
          </v-col>
          <v-col cols="7" md="8" sm="6">
            <v-card-text
              :id="'bookDetailsSellOptionId' + book.id"
              class="py-0">
              {{!book.sellOption ? 'Ja' : 'Nee'}}
            </v-card-text>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="hasValue(book.ownersNote)">
          <v-col class="py-0" cols="5" md="4" sm="6">
            <v-card-text class="py-0">Omschrijving</v-card-text>
          </v-col>
          <v-col class="py-0" cols="7" md="8" sm="6">
            <v-card-text
              :id="'bookDetailsOwnersNoteId' + book.id" 
              class="py-0">
              {{ book.ownersNote }}
            </v-card-text>
          </v-col>
        </v-row>
        <v-row>
          <v-img
            v-for="(image, index) in book.images"
            :key="index"
            max-width="150"
            :src="image.fileNameFullPath"
            @click="selectedImage = image; dialogPicture = true"
            class="ma-2"
          ></v-img>
        </v-row>
      </v-col>
      <v-col>
        <v-row v-if="book.sellOption !== 3">
          <v-col cols="5" md="2"></v-col>
          <v-col cols="2" md="3">
            <v-btn
              :id="'bookDetailsEditMyBookId' + book.id"
              text color="primary" 
              @click="updateBook(book)">
              <v-icon> mdi-pencil-outline </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="1" md="1">
            <v-btn
              :id="'bookDetailsDeleteMyBookId' + book.id" 
              text
              :loading="loading && bookToRemoveId === book.id"
              color="primary"
              @click="removeBook(book)"
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import BookService from '../services/BookService'

export default {
  data() {
    return {
      dialog: false,
      bookToRemoveId: 0,
      loading: false,
      dialogPicture: false,
      selectedImage:null,
    }
  },
  props: {
    book: {
      type: Object,
      default: () => {},
      loading: true,
    },
    searched: Boolean,
  },
  created() {},
  methods: {
    ...mapGetters(['getAccessToken']),
    hasValue(value) {
      return value != null && value != ''
    },
    getConditionName(val) {
      if (val === 0) return 'Zo goed als nieuw'
      else if (val === 1) return 'Goed'
      else if (val === 2) return 'Redelijk'
      else if (val === 3) return 'Nieuw'
      else return 'Onbekend'
    },
    getDeliveryOption(val) {
      if (val === 0) return 'Verzenden'
      else if (val === 1) return 'Ophalen'
      else return 'Onbekend'
    },
    openDialog() {
      this.dialog = true
    },
    convertState(state) {
      if (state == 0) return 'Zo goed als nieuw'
      if (state == 1) return 'Goed'
      if (state == 2) return 'Redelijk'
      if (state == 3) return 'Nieuw'
      if (state >= 4) return 'Onbekend'
    },
    async removeBook(book) {
      try {
        this.loading = true
        this.bookToRemoveId = book.id
        await BookService.removeBook(this.getAccessToken(), book.id)
        this.$emit('onRemovedBook')
      } catch {}

      this.loading = false
    },
    updateBook(book) {
      this.$router.push({ name: 'UpdateMyBook', params: { bookToEdit: book } })
    },
  },
  components: {},
}
</script>

<style scoped>
.label {
  font-weight: bold;
  margin-right: 15%;
}
.action-button {
  padding-top: 10%;
}

@media (min-width: 1px) and (max-width: 960px) {
  .action-button {
    padding-top: 2%;
  }
}

@media (min-width: 50px) and (max-width: 680px) {
  .label {
    margin-left: 15%;
  }
}
@media (min-width: 681px) and (max-width: 1904) {
  .label {
    margin-left: 25%;
  }
}
</style>