<template>
    <v-form>
         <v-container>
             <v-row>
                 <v-col cols="3" class="hidden-md-and-down">
                     <UserMenuCard></UserMenuCard>   
                 </v-col>
                 <v-col>
                     <v-row>
                        <v-col cols="12" md="6">
                            <v-menu
                                v-model="menuDateFrom"
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                min-width="auto">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        class="pt-2" 
                                        v-model="dateFrom"
                                        label="Vanaf"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        outlined
                                        dense
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="dateFrom" 
                                    no-title 
                                    dense
                                    scrollable>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-menu
                                v-model="menuDateTo"
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                min-width="auto">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        class="pt-2" 
                                        v-model="dateTo"
                                        label="tot"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        outlined
                                        dense
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="dateTo" 
                                    no-title 
                                    dense
                                    scrollable>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        
                        <v-row justify="end" class="mr-3 mb-2">
                            <v-btn @click="getAllStatistics()" color="primary">
                                Laden
                            </v-btn>
                        </v-row>
                        <v-col cols="12">
                            <v-row justify="center" v-if="loading">
                                <v-progress-circular
                                indeterminate
                                color="primary">
                                </v-progress-circular>
                            </v-row>
                            <v-card class="mb-4">
                                <v-col>
                                    Verkocht: {{ totalSold.toFixed(2) }}
                                </v-col>
                                <v-col>
                                    Betaald: {{ totalPaid.toFixed(2) }}
                                </v-col>
                                <v-col>
                                    Totale winst: {{ totalProfit.toFixed(2) }}
                                </v-col>
                                <v-col>
                                    Gemiddeld winst per verkoper: {{ totalProfitAveragePerIntervener.toFixed(2) }}
                                </v-col>
                                <v-col>
                                    Verloren: {{ totalLost.toFixed(2) }}
                                </v-col>
                                <v-col>
                                    Geparkeerd: {{ totalParked.toFixed(2) }}
                                </v-col>
                                <v-col>
                                    Toegewezen: {{ totalAssigned.toFixed(2) }}
                                </v-col>
                            </v-card>

                            <v-card v-for="(statistic, index) in statisticsFinished.intervenesOfInterveners" :key="index">
                                <v-col>
                                    <span v-if="statistic.intervener != null 
                                    && statistic.intervener.firstName != null">
                                        {{ statistic.intervener.firstName }}
                                </span>
                                </v-col>
                                <v-col>
                                    Winst (afgerond): {{ calculateTotalProfitPerIntervener(statistic.intervenes).toFixed(2) }}
                                </v-col>
                                <v-col>
                                    Gemiddeld winst per boek: {{ (calculateTotalProfitPerIntervener(statistic.intervenes).toFixed(2)/statistic.intervenes.length).toFixed(2) }}
                                </v-col>
                                <v-col>
                                    Hoogste winst: {{ calculateHighestProfit(statistic.intervenes).toFixed(2) }}
                                </v-col>
                                <v-col>
                                    Laagste winst: {{ calculateLowestProfit(statistic.intervenes).toFixed(2) }}
                                </v-col>
                                <v-col>
                                    Geparkeerd: {{ calculateParkedPerIntervener(statistic.intervener.id).toFixed(2) }}
                                </v-col>
                                <v-col>
                                    Toegewezen: {{ calculateAssignedPerIntervener(statistic.intervener.id).toFixed(2) }}
                                </v-col>
                                <v-col>
                                    Betaald (status): {{ calculatePaidPerIntervener(statistic.intervener.id).toFixed(2) }}
                                </v-col>
                                <v-col>
                                    <v-expansion-panels>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                Intervenes
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-row class="secondary">
                                                    <v-col>Winst</v-col>
                                                    <v-col>Verkocht</v-col>
                                                    <v-col>Datum</v-col>
                                                </v-row>
                                                <v-row v-for="(intervene, indexIntervene) in statistic.intervenes" :key="indexIntervene">
                                                    <v-col>
                                                        {{ Number(intervene.profit).toFixed(2) }}
                                                    </v-col>
                                                    <v-col>
                                                        €{{ Number(intervene.soldFor).toFixed(2) }}
                                                    </v-col>
                                                    <v-col>
                                                        {{ getFriendlyDate(intervene.date) }} {{ getFriendlyTime(intervene.date) }}
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-col>
                            </v-card>
                           
                        </v-col>
                     </v-row>
                </v-col>
                
            </v-row>
        </v-container>
    </v-form>
</template>
        
<script>
import { mapGetters } from 'vuex';
import UserMenuCard from '../../../components/UserMenuCard.vue';
import InterveneService from '../../../services/InterveneService';

   export default {
    data () {
    return {
            precentageDiscounts:[],
            discount: {},
            loading: false,
            dateFrom: this.getDateInAmsterdam(-14),
            dateTo: this.getDateInAmsterdam(0),
            menuDateTo: false,
            menuDateFrom: false,
            statisticsFinished:[],
            statisticsPaid:[],
            statisticsLost:[],
            statisticsIgnor:[],
            statisticsParked:[],
            statisticsAssigned:[],
            statisticsOpen:[],
            totalProfit: 0.0,
            totalLost: 0.0,
            totalIgnored: 0.0,
            totalParked: 0.0,
            totalProfitAveragePerIntervener: 0.0,
            totalAssigned: 0.0,
            totalPaid: 0.0,
            totalSold: 0.0
        }
    },
    components:{
        UserMenuCard,
    }, 
    methods:{
        ...mapGetters(['getAccessToken']),
        
        async getAllStatistics(){
            this.loading = true;
            this.statisticsFinished = await this.getStatistics(6);
            this.statisticsPaid = await this.getStatistics(5);
            this.statisticsLost = await this.getStatistics(4);
            this.statisticsIgnor = await this.getStatistics(3);
            this.statisticsParked = await this.getStatistics(2);
            this.statisticsAssigned = await this.getStatistics(1);
            this.statisticsOpen = await this.getStatistics(0);
            
            this.calculateTotalProfit();
            this.calculateTotalLost();
            this.calculateTotalParked();
            this.calculateTotalAssigned();
            this.calculateTotalPaid();
            
            this.loading = false;
        },

        async getStatistics(state){
            
            let statistics = [];
            try{
                let result = await InterveneService.getStatistics(this.getAccessToken(), this.dateFrom, this.dateTo, state);
                statistics = result.interveneStatistics;
            } catch{

            }
            return statistics;
        },

        calculateTotalLostPerIntervener(intervenes){
            let result = 0.0;
            intervenes.forEach(intervene => {
                result += intervene.soldFor;
            });        
            return result;
        },

        calculateTotalParkedPerIntervener(intervenes){
            let result = 0.0;
            intervenes.forEach(intervene => {
                result += intervene.soldFor;
            });        
            return result;
        },

        calculateTotalAssignedPerIntervener(intervenes){
            let result = 0.0;
            intervenes.forEach(intervene => {
                result += intervene.soldFor;
            });        
            return result;
        },

        calculateTotalPaidPerIntervener(intervenes){
            let result = 0.0;
            intervenes.forEach(intervene => {
                result += intervene.boughtFor;
            });        
            return result;
        },

        calculateTotalSoldPerIntervener(intervenes){
            let result = 0.0;
            intervenes.forEach(intervene => {
                result += intervene.soldFor;
            });        
            return result;
        },

        calculateTotalLost(){
            let lost = 0.0;
            this.statisticsLost.intervenesOfInterveners.forEach(statistic => {
                lost += this.calculateTotalLostPerIntervener(statistic.intervenes);
            })

            this.totalLost = lost;
            this.totalLostLostPerIntervener = lost/this.statisticsLost.intervenesOfInterveners.length;

        },

        calculateAssignedPerIntervener(id){
            let assigned = 0.0;
            if(this.statisticsAssigned.intervenesOfInterveners != null){
                this.statisticsAssigned.intervenesOfInterveners.forEach(item =>{
                    if(item.intervener.id === id){
                        assigned = this.calculateTotalAssignedPerIntervener(item.intervenes);
                    }
                })
            }
            return assigned;
        },

        calculatePaidPerIntervener(id){
            let paid = 0.0;
            if(this.statisticsPaid.intervenesOfInterveners != null){
                this.statisticsPaid.intervenesOfInterveners.forEach(item =>{
                    if(item.intervener.id === id){
                        paid = this.calculatePaidPerIntervener(item.intervenes);
                    }
                })
            }
            return paid;
        },

        calculateParkedPerIntervener(id){
            let parked = 0.0;
            if(this.statisticsParked.intervenesOfInterveners != null){
                this.statisticsParked.intervenesOfInterveners.forEach(item =>{
                    if(item.intervener.id === id){
                        parked = this.calculateTotalParkedPerIntervener(item.intervenes);
                    }
                })
            }
            return parked;
        },

        calculateTotalParked(){
            let parked = 0.0;
            this.statisticsParked.intervenesOfInterveners.forEach(statistic => {
                parked += this.calculateTotalParkedPerIntervener(statistic.intervenes);
            })

            this.totalParked = parked;
            this.totalParkedPerIntervener = parked/this.statisticsParked.intervenesOfInterveners.length;
        },

        calculateTotalAssigned(){
            let assigned = 0.0;
            this.statisticsAssigned.intervenesOfInterveners.forEach(statistic => {
                assigned += this.calculateTotalAssignedPerIntervener(statistic.intervenes);
            })

            this.totalAssigned = assigned;
            this.totalAssignedPerIntervener = assigned/this.statisticsAssigned.intervenesOfInterveners.length;
        },

        calculateTotalPaid(){
            let paid = 0.0;
            this.statisticsPaid.intervenesOfInterveners.forEach(statistic => {
                paid += this.calculateTotalPaidPerIntervener(statistic.intervenes);
            })

            this.totalAssigned = paid;
            this.totalAssignedPerIntervener = paid/this.statisticsPaid.intervenesOfInterveners.length;
        },

        calculateTotalProfit() {
            let profit = 0.0;
            let paid = 0.0;
            let sold = 0.0;

            if (this.statisticsFinished && this.statisticsFinished.intervenesOfInterveners) {
                this.statisticsFinished.intervenesOfInterveners.forEach(statistic => {
                    profit += this.calculateTotalProfitPerIntervener(statistic.intervenes);
                    paid += this.calculateTotalPaidPerIntervener(statistic.intervenes);
                    sold += this.calculateTotalSoldPerIntervener(statistic.intervenes);
                });

                this.totalProfit = profit;
                this.totalPaid = paid;
                this.totalSold = sold;

                const numberOfInterveners = this.statisticsFinished.intervenesOfInterveners.length;
                this.totalProfitAveragePerIntervener = numberOfInterveners > 0 ? profit / numberOfInterveners : 0.0;
            } else {
                this.totalProfit = 0.0;
                this.totalProfitAveragePerIntervener = 0.0;
            }
        },

        calculateTotalProfitPerIntervener(intervenes){
            let profit = 0.0;
            intervenes.forEach(intervene => {
                profit += intervene.profit;
            });
            return profit;
        },

        calculateProfitPerIntervener(intervenes){
            let profit = 0.0;
            if(intervenes != null){
                intervenes.forEach(intervene => {
                    profit += intervene.profit;
                });
            }
                    
            return profit;
        },

        calculateHighestProfit(intervenes){
            let profit = 0.0;
            intervenes.forEach(intervene => {
                if(profit < intervene.profit){
                    profit = intervene.profit;
                }
            });        
            return profit;
        },

        calculateLowestProfit(intervenes){
            let profit = Number.MAX_SAFE_INTEGER;
            intervenes.forEach(intervene => {
                if(profit > intervene.profit){
                    profit = intervene.profit;
                }
            });        
            return profit;
        },

        getFriendlyDate(val){
            var date = new Date(val);
            return date.getDate()  + "-" + (date.getMonth()+1) + "-" + date.getFullYear();
        },

        getFriendlyTime(val){
            var date = new Date(val);
            return date.getHours() + ":" + String(date.getMinutes()).padStart(2, "0");
        },

        getDateInAmsterdam(daysToAdd = 0) {
            const today = new Date();
            today.setDate(today.getDate() + daysToAdd); // Adding/subtracting days

            const options = {
                year: 'numeric', month: '2-digit', day: '2-digit',
                timeZone: 'Europe/Amsterdam'
            };
            const dateFormatter = new Intl.DateTimeFormat('en-US', options);
            const formattedDate = dateFormatter.format(today);

            // The format() method returns the date in the format MM/DD/YYYY, we need to reorder to YYYY-MM-DD
            const [month, day, year] = formattedDate.split('/');
            return `${year}-${month}-${day}`;
        },
    }
}
    
</script>

