<template>
   <v-form>
        <v-container>
            <v-row>
                <v-col class="mt-3">
                    <v-row>
                        <v-col>
                            <h3>NOT CONFIRMED SHIPMENTS</h3>
                        </v-col>
                    </v-row>
                    <v-row>  
                        <v-col cols="12" md="4">
                            <v-menu
                                v-model="menuDateFrom"
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                min-width="auto">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="dateFrom"
                                        label="Vanaf datum"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        outlined
                                        dense
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker 
                                    v-model="dateFrom" 
                                    no-title 
                                    dense
                                    scrollable
                                    @input="menuDateFrom = false">
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-menu
                                v-model="menuDateTo"
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                min-width="auto">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="dateTo"
                                        label="Vanaf datum"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        outlined
                                        dense
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker 
                                    v-model="dateTo" 
                                    no-title 
                                    dense
                                    scrollable
                                    @input="menuDateFrom = false">
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col>
                            <v-btn @click="getUnconfirmedShipments()" :loading="loading" color="primary">
                                LOAD
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-for="(shipment, index) in shipments" :key="index">
                        <v-col>
                            <v-card>
                                <v-card-title>
                                    {{shipment.id}}
                                </v-card-title>
                                <v-card-title>
                                    {{shipment.barcode}}
                                </v-card-title>
                                <v-card-subtitle>
                                    {{shipment.inserted}}
                                </v-card-subtitle>
                                <v-card-subtitle v-if="!shipment.status || shipment.status.length == 0">
                                    Geen status bekend
                                </v-card-subtitle>
                                <v-card-subtitle v-if="shipment.status && shipment.status.length > 0">
                                    {{shipment.status.length}}
                                    {{shipment.status[0].phaseDescription}} - {{shipment.status[0].statusDescription}}
                                </v-card-subtitle>
                                <v-card-text v-for="(shipmentLabel, shipmentLabelIndex) in shipment.labels" :key="shipmentLabelIndex">
                                        <a :href="'data:application/pdf;base64,'+shipmentLabel.content" download="verzendbrief.pdf">
                                        download verzend label
                                        </a>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="3"></v-col>
                <v-col>
                    <v-row>
                        <v-pagination
                            v-if="totalPages >0"
                            style="padding-bottom: 3%; padding-top: 3%"
                            v-model="pageNumber"
                            :length="totalPages"
                            @input="next()"
                            :total-visible="7">
                        </v-pagination>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
   </v-form>
</template>

<script>
import ShipmentService from '../../services/ShipmentService';
import { mapGetters } from 'vuex';

export default {
    propss:['action'],
    data () {
      return {
        loading: false,
        pageSize: 5,
        pageNumber: 1,
        totalPages:0,
        dateFrom: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateTo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menuDateFrom:false,
        menuDateTo:false,
        shipments:[],
        tab: null,
      }
    },
    methods:{
        ...mapGetters(['getAccessToken']),

        async next(){
            this.loading = true;
            let data = await ShipmentService.getUnconfirmedShipments(this.getAccessToken(), this.dateFrom, this.dateTo, this.pageNumber, this.pageSize);
            this.shipments = data.shipments;
            this.totalPages = Math.ceil(data.totalCount /this.pageSize);
            this.loading = false;
        },

        async getUnconfirmedShipments(){
            this.loading = true;
            let data = await ShipmentService.getUnconfirmedShipments(this.getAccessToken(), this.dateFrom, this.dateTo, this.pageNumber, this.pageSize);
            this.shipments = data.shipments;
            this.totalPages = Math.ceil(data.totalCount /this.pageSize);
            this.loading = false;
        },
    },
    components:{
    },
}
</script>