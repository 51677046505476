<template>
    <v-card width="550" min-height="600" flat>
        <v-row class="py-4">
            <v-col>
                <v-card-text class="pt-0"> 
                    <v-img max-width="100"
                        :src="book.publishedBook.imageFileName">
                    </v-img>
                </v-card-text>
                <v-card-text class="py-0"> 
                    ISBN: {{ book.publishedBook.isbn }} 
                </v-card-text>
                <v-card-text class="py-0"> 
                    Druk: {{ book.publishedBook.edition }} 
                </v-card-text>
                <v-card-text class="py-0"> 
                    {{ book.publishedBook.title }} 
                </v-card-text>
            </v-col>
        </v-row>
        <v-row class="px-4">
            <v-col cols="3" class="py-0">
                <v-text-field
                    v-model="book.price" 
                    outlined 
                    dense 
                    placeholder="bijv. 23,99"
                    label="Prijs in €">
                </v-text-field>
            </v-col>
            <v-col cols="4" class="py-0">
                <v-select
                    return-object
                    :items="conditions"
                    v-model="book.condition"
                    :item-text="'option'"
                    :item-value="'id'"
                    dense
                    label="Staat" outlined>
                </v-select>   
            </v-col>
            <v-col cols="12" class="py-0">
                <v-textarea
                    outlined
                    v-model="book.ownersNote"
                    name="input-7-4"
                    label="Omschrijving">
                </v-textarea>
            </v-col>
        </v-row>
        <v-row class="my-0 px-4">
            <v-col cols="6">
                <v-select
                v-model="selectedAccount" 
                :items="accounts"
                chips
                label="Boekshare Accounts"
                :item-text="'userName'"
                :item-value="'id'"
                single-line
                dense
                class="pb-0"
                outlined
                @input="userSelected()">
                </v-select>
            </v-col>
            <v-col cols="12">
                <v-card v-if="userIsSelected">
                    <v-col cols="12" v-if="books != null && books.length > 0">Boeken van de verkoper</v-col>
                    <v-col cols="12" v-if="books.length == 0">Deze verkoper heeft geen boeken</v-col>
                    <v-col cols="12">
                        <h3 v-if="isAlreadySellingTheBook" class="red--text">Deze verkoper verkoopt al deze boek</h3>
                        <v-checkbox v-if="isAlreadySellingTheBook" label="Alsnog doorgaan?" v-model="forceAddingBook"></v-checkbox>
                        <v-btn v-if="forceAddingBook || !isAlreadySellingTheBook" color="primary" :loading="loading" @click="addBook()">Toevoegen</v-btn>
                    </v-col>
                    <v-col cols="12" v-for="(book, index) in books" :key="index">
                        {{ book.publishedBook.title }} - {{ book.publishedBook.isbn }} - €{{ book.price }} - {{ book.insertDate }}
                    </v-col>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-pagination
                v-if="totalPages > 1"
                style="padding-bottom: 3%; padding-top: 3%"
                v-model="pageNumber"
                :length="totalPages"
                @input="userSelected()"
                :total-visible="4">
            </v-pagination>
        </v-row>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import StudentService from '../../../services/StudentService';
import BookService from '../../../services/BookService';
import AdminBookService from '../../../services/AdminBookService';

export default {
    props: ['book'],
    data () {
       return {
        accounts: [],
        books: [],
        selectedAccount: null,
        totalPages:0,
        pageNumber: 1,
        pageSize: 5,
        userIsSelected: false,
        forceAddingBook: false,
        isAlreadySellingTheBook: false,
        loading: false,
        conditions: [
            { id: 0, option: 'Zo goed als nieuw' },
            { id: 1, option: 'Goed' },
            { id: 2, option: 'Redelijk' },
            { id: 3, option: 'Nieuw' },
        ],
       }
    },

    async created(){
        await this.getBoekShareAccounts();
    },

    watch: {
        book(val){
            this.resetForm();
        }
    },

    methods:{
        ...mapGetters(['getAccessToken']),
        async getBoekShareAccounts(){
            let result = await StudentService.getBoekShareAccounts(this.getAccessToken());
            this.accounts = result.boekShareAcocunts;
        },

        async userSelected(){
            let result = await BookService.getBooksOfStudentAsAdmin(this.getAccessToken(), this.selectedAccount, this.pageNumber, this.pageSize, true);
            this.books = result.books;
            this.userIsSelected = true;
            this.totalPages = Math.ceil(result.totalCount / this.pageSize);
            await this.isAlreadySellingBook();
        },

        async isAlreadySellingBook(){
            let result = await BookService.isStudentAlreadySellingBook(this.getAccessToken(), this.selectedAccount, this.book.publishedBook.id);
            this.isAlreadySellingTheBook = result.result;
        },

        async addBook(){
            this.loading = true;
            try{
                let book = {};
                book.publishedBookId = this.book.publishedBook.id;
                book.sellOption = 0;
                book.condition = this.book.condition.id;
                book.deliveryOption = this.book.deliveryOption;
                book.price = this.book.price;
                book.ownersNote = this.book.ownersNote;
                book.readyForSaleDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
                await AdminBookService.addBook(this.getAccessToken(), this.selectedAccount, book);

            } catch{

            }
            
            this.loading = false;
            this.resetForm();
        },

        resetForm(){
            this.books = [];
            this.selectedAccount = null;
            this.userIsSelected = false;
            this.forceAddingBook = false;
            this.isAlreadySellingTheBook = false;
            this.totalPages = 0;
            this.pageNumber = 1;
        }
    }
}
</script>