<template>
   <v-form>
        <v-container>
            <v-row>
                <v-col class="mt-3" cols="10">
                    <v-row>
                       <v-col cols="12" md="8">
                            <v-menu
                                v-model="menuDateFrom"
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                min-width="auto">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="dateFrom"
                                        label="Vanaf datum"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        outlined
                                        dense
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker 
                                    v-model="dateFrom" 
                                    no-title 
                                    dense
                                    scrollable
                                    @input="dateSelected()">
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-select
                            v-model="selectedState" 
                            :items="states"
                            chips
                            label="States"
                            single-line
                            dense
                            outlined
                            @input="dateSelected()">
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row v-if="booksOverview && booksOverview.booksData">
                        <v-card width="100%" class="ma-4" v-for="(bookData, index) in booksOverview.booksData" :key="index">
                            <v-card-title>{{bookData.title}}</v-card-title>
                            <v-card-subtitle>ISBN: {{bookData.isbn}}</v-card-subtitle>
                            <v-card-text>Aantal: {{bookData.quantity}}</v-card-text>
                        </v-card>
                    </v-row>
                </v-col>
            </v-row>
            <v-row justify="center" align="center" v-if="errorMessage || loading"> 
                <v-col cols="6" v-if="errorMessage">
                    <v-card-text>
                    {{errorMessage}}
                    </v-card-text>
                </v-col>
                <v-col class="my-8" cols="6">
                    <v-progress-circular
                    v-if="loading"
                    indeterminate
                    color="primary">
                    </v-progress-circular>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="3"></v-col>
                <v-col>
                    <v-row>
                        <v-pagination
                            v-if="totalPages >0"
                            style="padding-bottom: 3%; padding-top: 3%"
                            v-model="pageNumber"
                            :length="totalPages"
                            @input="load()"
                            :total-visible="7">
                        </v-pagination>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
   </v-form>
</template>

<script>
import TransactionService from '../../services/TransactionService';
import { mapGetters } from 'vuex';

export default {
    data () {
      return {
        loading: false,
        pageSize: 5,
        pageNumber: 1,
        errorMessage: null,
        totalPages:0,
        booksOverview: null,
        menuDateFrom: false,
        states:['requested', 'finished'],
        selectedState: 'finished',
        dateFrom: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), 
      }
    },
    async created(){
        await this.load();
    },
    methods:{
        ...mapGetters(['getAccessToken']),
        async load(){
            try{
                this.errorMessage = null;
                this.loading = true;
                let data = await TransactionService.getTopsSoldBooks(this.getAccessToken(), this.pageNumber, this.pageSize, this.dateFrom, this.selectedState);
                this.booksOverview = data.booksOverview;
                this.totalPages = Math.ceil(data.totalCount /this.pageSize);
                this.loading = false;
            }catch{
                this.loading = false;
                this.errorMessage = 'Er is helaas een fout opgetreden.'
            }
            
        },
        async dateSelected(){
            this.menuDateFrom = false;
            await this.load();
        }
    }
}
</script>