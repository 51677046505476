<template>
    <v-container>
        <v-row class="white mx-1">
            <v-col class="white" cols="12" md="7">
                <StudentAddressComponent
                    @onAddressSavedEvent="onAddressSavedEvent()" 
                    @onControlValidEvent="onControlValidEvent()" 
                    @onAddressFailedEvent="onAddressFailedEvent()" 
                    @onNoAddressFoundEvent="onNoAddressFoundEvent()" 
                    @onSurnameSavedEvent="onSurnameSavedEvent()"
                    @onSurnameFailedEvent="onSurnameFailedEvent()"
                    @onNameSavedEvent="onNameSavedEvent()"
                    @onNameFailedEvent="onNameFailedEvent()"
                    class="white mr-2">
                </StudentAddressComponent>
            </v-col>
            <v-col class="white ml-md-3" cols="12" md="4">
                <StudentStudyComponent 
                    class="white" 
                    @terug="terug()" 
                    @onSaveStudyClickedEvent="onSaveStudy()">
                </StudentStudyComponent>
            </v-col>
        </v-row>
        <v-row class="white mx-1 mt-6">
            <v-col class="white" cols="12" md="8">
                <DiscountComponent @discount="checkDiscount"></DiscountComponent>
            </v-col>
        </v-row>
        <v-row class="mb-10">
            <v-col cols="12">
                <v-card flat>
                    <v-col class="pa-md-10">
                        <div v-for="(book, indexCartItems) in cartItems" :key="indexCartItems">
                            <v-row>
                                <v-col>
                                    <v-row>
                                        <v-col class="mb-2">
                                            <v-img max-width="70" :src="book.publishedBook.imageFileName">
                                            </v-img>
                                        </v-col>
                                        <v-col cols="10" class="mt-4">
                                            <v-row>
                                                <v-col cols="12" class="py-0">                                    
                                                    <label class="font-weight-bold" :id="'labelTitle' + book.id">{{book.publishedBook.title}}</label>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" class="py-0">
                                                    <label class="font-weight-bold mr-2">ISBN: </label><label :id="'labelIsbn' + book.id">{{book.publishedBook.isbn}}</label>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" class="py-0">
                                                    <label class="font-weight-bold mr-2">Druk: </label><label :id="'labelEdition' + book.id">{{book.publishedBook.edition}}</label>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="12" class="py-0">
                                            <label class="font-weight-bold mr-2">Verkoper:</label><label :id="'labelSeller' + book.id">{{book.owner.lastName}}, {{book.owner.firstName}}</label>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" class="py-0">
                                            <label class="font-weight-bold mr-2">Staat van boek: </label><label :id="'labelBookCondition' + book.id">{{getConditionName(book.condition)}}</label>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" class="py-0">
                                            <label class="font-weight-bold mr-2">Verzending: </label><label>PostNL</label>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col>
                                    <v-row justify="start">
                                        <v-col class="py-0">
                                            <label class="font-weight-bold mr-2">Prijs: </label><label :id="'labelBookPrice' + book.id">€{{parseFloat(book.price).toFixed(2)}}</label>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="end">
                                        <v-col cols="5" md="2" class="pt-0 mb-3">
                                            <v-btn :id="'removeBookFromCart' + book.id" :loading="loadingReleaseBook && loadingIndex == book.id" class="pl-0 mt-4" text color="primary" @click="removeItemFromCart(book)">
                                                <v-icon>mdi-delete</v-icon>
                                                Verwijder
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </div>
                        <v-divider></v-divider>
                        <v-row class="py-4">
                            <v-col cols="8" md="4">
                                <label class="font-weight-bold">Totaal verzendkosten</label>
                            </v-col>
                            <v-col>
                                <v-row justify="end" class="mt-0"> 
                                    <label class="font-weight-bold" :id="'labelBookShippingCost'">€{{parseFloat(calculateShippingCosts()).toFixed(2)}}</label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-divider class="grey mx-md-8"></v-divider>
                        <div v-for="(shipment, indexSellerForShipment) in booksGroupedBySellerForShipment" :key="`shipment-${indexSellerForShipment}`">
                            <v-card flat class="mb-3 mb-0 mt-2 px-md-8">
                                <v-row class="my-1 mx-0"> 
                                    <v-col cols="5" md="5">
                                        <v-row>
                                            <label class="font-weight-bold" :id="'labelBookShipmentFirstLastName' + shipment.owner.id">{{shipment.owner.lastName}}, {{shipment.owner.firstName}}</label>
                                        </v-row>
                                    </v-col>                        
                                    <v-col cols="12" md="7">
                                        <div v-for="(bookOfOneOwner, indexBookOfOwner) in shipment.books" :key="`book-${indexSellerForShipment}-${indexBookOfOwner}`">
                                            <v-row justify="end"> 
                                                <label class="pt-2">
                                                    {{bookOfOneOwner.publishedBook.title}}
                                                </label>
                                            </v-row>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row class="pb-4">
                                    <v-col cols="8" md="7">
                                        <label>Kopersbescherming</label>
                                    </v-col>
                                    <v-col>
                                        <v-row justify="end" class="py-4 mr-md-n9"> 
                                            <label v-if="isUserLoggedIn()" :id="'labelBuyersProtection' + shipment.owner.id">€0.99</label>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <v-divider class="grey mx-md-8"></v-divider>
                        </div>
                        <v-row class="py-4">
                            <v-col cols="8" md="4">
                                <label class="font-weight-bold">Totaal bedrag boek(en)</label>
                            </v-col>
                            <v-col>
                                <v-row justify="end" class="py-4"> 
                                    <label class="font-weight-bold" id="labelTotalMoneyNeededForBuyingBooks">€{{parseFloat(getTotalMoneyNeededForBuyingBooks()).toFixed(2)}}</label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row class="py-4">
                            <v-col cols="8" md="4">
                                <label class="font-weight-bold">Totaal bedrag</label><br/>
                                <label class="font-weight-thin">* incl. verzendenkosten</label><br/>
                                <label class="font-weight-thin">* incl. kopersbescherming</label><br/>
                            </v-col>
                            <v-col>
                                <v-row justify="end" class="py-4"> 
                                    <label class="font-weight-bold" id="labelGrandTotal">€{{ calculateGrandTotal() }}</label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-divider v-if="totalDiscount != null && totalDiscount > 0.0"></v-divider>
                        <v-row class="pt-4 pb-4" v-if="totalDiscount != null && totalDiscount > 0.0">
                            <v-col cols="8" md="4">
                                <label class="font-weight-bold">Korting</label>
                            </v-col>
                            <v-col>
                                <v-row justify="end" class="py-4"> 
                                    <label id="labelBuyersDiscount">€{{ parseFloat(totalDiscount).toFixed(2) }}</label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row class="pt-8 pb-4">
                            <v-col cols="8" md="4">
                                <label class="font-weight-bold">Bedrag in je wallet</label>
                            </v-col>
                            <v-col>
                                <v-row justify="end" class="py-4"> 
                                    <label id="labelMoneyInWallet" v-if="isUserLoggedIn()">€{{parseFloat(getMoney()).toFixed(2)}}</label>
                                    <router-link v-if="!isUserLoggedIn()" to="/login"
                                    class="btn btn-link mr-4" style="float: right;">Login om de inhoud van je wallet te zien</router-link>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row class="py-4">
                            <v-col cols="10" md="4">
                                <label class="font-weight-bold">Totaal te betalen</label>
                            </v-col>
                            <v-col>
                                <v-row justify="end" class="py-4"> 
                                    <label id="labelTotalToPay" class="font-weight-bold">€{{parseFloat(calculateTotal()).toFixed(2)}}</label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-btn color="primary" block outlined @click="proceedWithShopping()">
                                    VERDER WINKELEN
                                </v-btn>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-btn
                                id="shoppingCartPayButton" 
                                color="primary" 
                                block 
                                :disabled="!formValid ||loading"
                                :loading="loading"
                                @click="onPayShoppingCart()">
                                    AFREKENEN - Op naar de Finish!
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="3" md="4"></v-col>
                            <v-col>
                                <h4 class="red--text" v-if="resultMessage">{{resultMessage}}</h4>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BookService from '../services/BookService';
import CheckoutService from '../services/CheckoutService'
import StudentAddressComponent from '../components/StudentAddressComponent.vue'
import StudentStudyComponent from '../components/StudentStudyComponent.vue'
import DiscountComponent from '../components/DiscountComponent.vue'

export default {
    data: () => ({
        cartItems: [],
        booksGroupedBySellerForShipment: [],
        booksGroupedBySellerForPickup: [],
        loading: false,
        loadingReleaseBook: false,
        resultMessage:null,
        totalCost: 0.0,
        validSubscription: false,
        loadingIndex: 0,
        showAddressDialogComponent: false,
        addressSuccessful:false,
        nameSuccessful:false,
        surnameSuccessful:false,
        totalDiscount: 0.0,
        discountNumber: '',
    }),
    
    components:{
        StudentAddressComponent,
        StudentStudyComponent,
        DiscountComponent
    },
    
    computed: {
      formValid() {
            let student = this.getStudent();
            return this.cartItems != null &&
            this.cartItems.length > 0 &&
            student.residentialAddress && 
            student.residentialAddress.street && 
            student.residentialAddress.street != '' && 
            student.residentialAddress.postalCode && 
            student.residentialAddress.postalCode != '' && 
            student.residentialAddress.houseNumber && 
            student.residentialAddress.houseNumber != '' && 
            student.residentialAddress.city && 
            student.residentialAddress.city != '' && 
            student.postalAddress && 
            student.postalAddress.street && 
            student.postalAddress.street != '' && 
            student.postalAddress.postalCode && 
            student.postalAddress.postalCode != '' && 
            student.postalAddress.houseNumber && 
            student.postalAddress.houseNumber != '' && 
            student.postalAddress.city && 
            student.postalAddress.city != '' && 
            this.addressSuccessful &&
            this.nameSuccessful &&
            this.surnameSuccessful &&
            this.calculateGrandTotal() >= 0.0;
      }
    },

    async created(){
        this.loadItemsFromCart();
        this.showAddressDialogComponent = this.shouldShowAddressDialog();
    },

    methods:{
        ...mapGetters(['getCart']),
        ...mapGetters(['getMoney']),
        ...mapGetters(['getStudent']),
        ...mapActions(['deleteFromCart']),
        ...mapGetters(['isUserLoggedIn']),
        ...mapGetters(['hasValidSubscription']),
        ...mapGetters(['getAccessToken']),
        

        calculateGrandTotal(){
            return parseFloat(
                this.getTotalMoneyNeededForBuyingBooks()
                + this.calculateShippingCosts() 
                + Number(0.99) * Number(this.booksGroupedBySellerForShipment.length))
                .toFixed(2);
        },

        onNameSavedEvent(){
            this.nameSuccessful = true;
        },

        onNameFailedEvent(){
            this.nameSuccessful = false;
        },

        onSurnameSavedEvent(){
            this.surnameSuccessful = true;
        },

        onSurnameFailedEvent(){
            this.surnameSuccessful = false;
        },

        onControlValidEvent(){
            this.nameSuccessful = true;
            this.addressSuccessful = true;
            this.surnameSuccessful = true;
        },

        proceedWithShopping(){
            this.$router.push({path: '/'});
        },

        onSaveStudy(){
            this.showAddressDialogComponent = false;
        },

        checkDiscount({ number, totalDiscount, typeOfDiscount }){

            this.discountNumber = number;

            if(typeOfDiscount === 'Percentage'){
                this.totalDiscount = (this.calculatePriceOfBooks() / 100) * totalDiscount;
            } else if(typeOfDiscount === 'Fixed'){
                this.totalDiscount = totalDiscount;
            }
        },

        shouldShowAddressDialog(){
            return !(this.getStudent().postalAddress != null &&
                   this.getStudent().postalAddress != null && 
                   this.getStudent().postalAddress.street != null && 
                   this.getStudent().postalAddress.street.length > 0 &&
                   this.getStudent().residentialAddress != null && 
                   this.getStudent().residentialAddress.street && 
                   this.getStudent().residentialAddress.street.length > 0);

        },

        loadItemsFromCart(){
            this.cartItems = this.getCart();
            this.buildTradingOptions();
            this.calculateShippingCosts();
            this.validSubscription = this.hasValidSubscription();
        },

        async removeItemFromCart(item){

            this.loadingReleaseBook = true;
            this.loadingIndex = item.id;
            try{
                await BookService.releaseBook(this.getAccessToken(), item.id);
            } catch{}

            this.deleteFromCart(item.id);
            this.buildTradingOptions();
            this.calculateShippingCosts();
            this.loadingReleaseBook = false;
        },

        calculateTotal(){
            let totalCost = 0.0;
            totalCost += this.calculatePrice(this.getMoneyMissing());

            return totalCost;
        },

        getConditionName(condition){
            if(condition === 0){
                return "Zo goed als nieuw";
            }
            else if(condition === 1){
                return "Goed";
            }
            else if(condition === 2){
                return "Redelijk";
            }
            else if(condition === 3){
                return "Nieuw";
            }
            else{
                return "Onbekend";
            }

        },

        getDeliveryOptionName(deliveryOption){
            if(deliveryOption === 0){
                return "Verzenden";
            }
            else if(deliveryOption === 1){
                return "Ophalen";
            }
            else if(deliveryOption === 2){
                return "Verzenden en/of ophalen";
            }
             else if(deliveryOption === 3){
                return "Niet aangegeven";
            }
        },

        getTotalMoneyNeededForBuyingBooks(){
            let cart = this.getCart();
            let totalPrice = 0.0;
            cart.forEach(book => {
               totalPrice += book.price;
            });  
            return totalPrice;
        },

        calculatePrice(money){
            if(money < 0){
                let absMoney = Math.abs(Number(money)) - Number(this.totalDiscount);
                return (absMoney);
            }
            else{
                return 0;
            }
        },

        calculatePriceOfBooks(){
            let total = 0.0;
            let cart = this.getCart();
            cart.forEach(book => {
               total += book.price;
            });
            return total; 
        },

        getMoneyMissing(){
            let administrationCost = Number(0.99) * Number(this.booksGroupedBySellerForShipment.length);
            
            let total = administrationCost;
            total += this.calculatePriceOfBooks();

            let moneyInWallet = this.getMoney();
            let totalMissing = (Number(moneyInWallet) - Number(total)) - Number(this.calculateShippingCosts()) + Number(this.totalDiscount);
            if(totalMissing < 0.0){
                return (Number(this.getMoney()) - Number(total) - Number(this.calculateShippingCosts()));
            }
            else{
                return 0;
            }
        },

        calculateShippingCosts(){
            let totalShipmentCostForSeller = 0.0;
            this.booksGroupedBySellerForShipment.forEach(shipment => {
                let totalWeight = 0;       
                let totalHeight = 0;    

                shipment.books.forEach(book => {
                    totalWeight += Number(book.publishedBook.physicalProperties.weight);
                    totalHeight += Number(book.publishedBook.physicalProperties.height);
                });
                
                if(totalWeight<=2000 && totalHeight < 32) {
                    totalShipmentCostForSeller += 4.25;
                }
                else if(totalWeight>2000 && 
                        totalWeight<=10000 || 
                        (totalHeight >= 32 && totalHeight <= 500)){
                    totalShipmentCostForSeller += 6.95;
                }
                else if(totalWeight>10000 && 
                        totalWeight<=20000 ||
                        (totalHeight <= 500)){
                    totalShipmentCostForSeller += 13.20;
                }
            });  
            
            return totalShipmentCostForSeller;
        },

        buildTradingOptions(){
            
            let cart = this.getCart();
            
            let booksGroupedForShipment =[];
            let booksGroupedForPickup = [];
            
            cart.forEach(book => {    
                if(book.deliveryOption === 0) {
                    const result = booksGroupedForShipment.find(shipment => shipment.sellerId === book.owner.id);
                    if(result){
                        result.books.push(book);
                    } else {
                        let books = [];
                        books.push(book);
                        booksGroupedForShipment.push({sellerId:book.owner.id, owner:book.owner, books:books});
                    }
                } else {
                    const result = booksGroupedForPickup.find(shipment => shipment.sellerId === book.owner.id);
                    if(result){
                        result.books.push(book);
                    } else{
                        let books = [];
                        books.push(book);
                        booksGroupedForPickup.push({sellerId:book.owner.id, owner:book.owner, books:books});
                    }
                }
            });
            
            this.booksGroupedBySellerForShipment = booksGroupedForShipment;
            this.booksGroupedBySellerForPickup = booksGroupedForPickup;
        },

       isiPhoneDevice(){
            var ua = window.navigator.userAgent;
            return (window.navigator.vendor && window.navigator.vendor.indexOf('Apple') > -1 &&
                   window.navigator.userAgent &&
                   window.navigator.userAgent.indexOf('CriOS') == -1 &&
                   window.navigator.userAgent.indexOf('FxiOS') == -1);
        },

        onAddressSavedEvent(){
            this.addressSuccessful = true;
        },

        onAddressFailedEvent(){
            this.addressSuccessful = false;
        },

        onNoAddressFoundEvent(){
            this.addressSuccessful = false;
        },

        onPayShoppingCart() {
            let books = [];
            let cart = this.getCart();
            cart.forEach(book => {
                books.push(book.id);
            });

            this.resultMessage = null;
            this.loading = true;

            const processPayment = (checkoutResult) => {
                if (checkoutResult) {
                    return CheckoutService.pay(this.getAccessToken(), checkoutResult.checkout.id);
                } else {
                    // If there's no checkoutResult, we should not attempt to make a payment
                    throw new Error('Checkout failed, cannot proceed with payment');
                }
            };

            const processPaymentResult = (payResult) => {
                if (payResult) {
                    if (payResult.checkout.payment) {
                        let payment = payResult.checkout.payment;
                        if (this.isiPhoneDevice()) {
                            window.open(payment.checkoutLink, "_self");
                        } else {
                            window.open(payment.checkoutLink, "_self");
                        }
                    } else {
                        let studentId = payResult.checkout.checkoutBy.id;
                        let checkoutId = payResult.checkout.id;
                        this.$router.push({ name: 'CheckoutPaymentResult', params: { checkoutId: checkoutId, studentId: studentId } });
                    }
                } else {
                    // If there's no payResult, we should not attempt to route to CheckoutPaymentResult
                    throw new Error('Payment processing failed, cannot proceed to CheckoutPaymentResult');
                }
            };

            const handleError = () => {
                this.resultMessage = 'Er is een fout opgetreden.';
                this.loading = false;
            };

            if (this.isiPhoneDevice()) {
                if (this.isUserLoggedIn()) {
                    CheckoutService.checkout(this.getAccessToken(), books, [this.discountNumber])
                        .then(processPayment)
                        .then(processPaymentResult)
                        .catch(handleError);
                } else {
                    this.$router.push("/login");
                    this.loading = false;
                }
            } else {
                if (this.isUserLoggedIn()) {
                    CheckoutService.checkout(this.getAccessToken(), books, [this.discountNumber])
                        .then(processPayment)
                        .then(processPaymentResult)
                        .catch(handleError);
                } else {
                    this.$router.push("/login");
                    this.loading = false;
                }
            }
        }
    }
}
</script>