<template>
   <v-form>
        <v-container> 
            <v-row>
                <v-col class="mt-3">
                     <v-row>
                          <v-col>
                            <h3>SHIPMENTS</h3>
                          </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-menu
                                v-model="menuDateFrom"
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                min-width="auto">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        id="allShipmentsDateFromId"
                                        v-model="dateFrom"
                                        label="Vanaf datum"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        outlined
                                        dense
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker 
                                    id="allShipmentsDatePickerFromId"
                                    v-model="dateFrom" 
                                    no-title 
                                    dense
                                    scrollable
                                    @input="menuDateFrom = false">
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-menu
                                v-model="menuDateTo"
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                min-width="auto">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        id="allShipmentsDateToId"
                                        v-model="dateTo"
                                        label="Vanaf datum"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        outlined
                                        dense
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker 
                                    v-model="dateTo" 
                                    no-title 
                                    dense
                                    scrollable
                                    @input="menuDateFrom = false">
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col>
                            <v-btn id="allShipmentsLoadButtonId" @click="load()" :loading="loading" color="primary">
                                LOAD
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-for="(shipment, index) in shipments" :key="index">
                        <v-col>
                            <v-card>
                                 <v-card-title :id="'allShipmentsShipmentId' + shipment.id">
                                    {{shipment.id}}
                                </v-card-title>
                                <v-card-title :id="'allShipmentsBarcodetId' + shipment.barcode">
                                    {{shipment.barcode}}
                                </v-card-title>
                                <v-card-subtitle :id="'allShipmentsInsertedId' + shipment.inserted">
                                    {{shipment.inserted}}
                                </v-card-subtitle>
                                <v-card-subtitle v-if="!shipment.status || shipment.status.length == 0" :id="'allShipmentsNoStatusId' + shipment.id">
                                    Geen status bekend
                                </v-card-subtitle>
                                <v-card-subtitle 
                                    v-if="shipment.status && shipment.status.length > 0"
                                    :id="'allShipmentsLastStatusId' + shipment.id"
                                >
                                    {{shipment.status[(shipment.status.length - 1)].phaseDescription}} - {{shipment.status[(shipment.status.length -1)].statusDescription}}
                                </v-card-subtitle>
                                <v-card-text v-for="(shipmentLabel, shipmentLabelIndex) in shipment.labels" :key="shipmentLabelIndex">
                                        <a :href="'data:application/pdf;base64,'+shipmentLabel.content" download="verzendbrief.pdf">
                                        download verzend label
                                        </a>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="3"></v-col>
                <v-col>
                    <v-row>
                        <v-pagination
                            v-if="totalPages >0"
                            style="padding-bottom: 3%; padding-top: 3%"
                            v-model="pageNumber"
                            :length="totalPages"
                            @input="next()"
                            :total-visible="7">
                        </v-pagination>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
   </v-form>
</template>

<script>
import ShipmentService from '../../services/ShipmentService';
import { mapGetters } from 'vuex';
const moment = require('moment-timezone');

export default {
    data () {
      return {
        loading: false,
        pageSize: 5,
        pageNumber: 1,
        totalPages:0,
        dateFrom: this.getDateInAmsterdam(-1),
        dateTo: this.getDateInAmsterdam(1),
        menuDateFrom:false,
        menuDateTo:false,
        shipments:[],
      }
    },
    methods:{
        ...mapGetters(['getAccessToken']),
        getDateInAmsterdam(daysToAdd = 0) {
            const today = new Date();
            today.setDate(today.getDate() + daysToAdd); // Adding/subtracting days

            const options = {
                year: 'numeric', month: '2-digit', day: '2-digit',
                timeZone: 'Europe/Amsterdam'
            };
            const dateFormatter = new Intl.DateTimeFormat('en-US', options);
            const formattedDate = dateFormatter.format(today);

            // The format() method returns the date in the format MM/DD/YYYY, we need to reorder to YYYY-MM-DD
            const [month, day, year] = formattedDate.split('/');
            return `${year}-${month}-${day}`;
        },

        async load(){
            this.loading = true;
            let data = await ShipmentService.getShipments(this.getAccessToken(), this.dateFrom, this.dateTo, this.pageNumber, this.pageSize);
            this.shipments = data.shipments;
            this.totalPages = Math.ceil(data.totalCount /this.pageSize);
            this.loading = false;
        },
        async next(){
            this.loading = true;
            let data = await ShipmentService.getShipments(this.getAccessToken(), this.dateFrom, this.dateTo, this.pageNumber, this.pageSize);
            this.shipments = data.shipments;
            this.totalPages = Math.ceil(data.totalCount /this.pageSize);
            this.loading = false;
        }, 
    },
    components:{
    },
}
</script>