<template>
   <v-form>
        <v-container> 
            <v-row>
                <v-col cols="3" class="hidden-md-and-down">
                    <UserMenuCard></UserMenuCard>   
                </v-col>
                <v-col>
                    <v-row align="center" justify="center" v-if="loading">
                        <v-progress-circular
                        indeterminate
                        color="primary">
                        </v-progress-circular>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="10" class="ml-4">
                            <v-text-field v-model="searchText" placeholder="Email, username, firstname or lastname"></v-text-field>
                        </v-col>
                        <v-col>
                            <v-row align="end" justify="end" class="mr-0 pt-6 mb-2">
                                <v-btn color="primary" @click="search()">
                                    SEARCH
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" v-for="(student, index) in students" :key="index">
                            <v-card class="mb-4">
                                <v-card-title >{{student.firstName }} {{student.lastName}} </v-card-title>
                                <v-card-text class="py-0">Gebruikersnaam: {{student.userName}}</v-card-text>
                                <v-card-text class="py-0"> 
                                    Registratiedatum: {{student.registrationDate}}
                                </v-card-text>
                                <v-card-text class="py-0">
                                     E-mail: {{student.email}}
                                </v-card-text>
                                 <v-card-text class="py-0">
                                     Phone: {{student.phone}}
                                </v-card-text>
                                <v-card-text class="py-0" v-if="student.university">
                                     Universiteit: {{student.university.name}}
                                </v-card-text>
                                <v-card-text class="py-0" v-if="student.study">
                                     Studienaam: {{student.study.name}}
                                </v-card-text>
                                <v-card falt outlined elevation class="ma-4" v-if="!student.residentialAddress">
                                    <v-card-text>
                                        <span class="text-subtitle-2">
                                            Woonadres
                                        </span><br/>
                                       Geen
                                    </v-card-text>
                                </v-card>
                                <v-card falt outlined elevation class="ma-4" v-if="student.residentialAddress">
                                    <v-card-text>
                                        <span class="text-subtitle-2">
                                            Woonadres
                                        </span><br/>
                                        {{(student.residentialAddress.street)}} {{(student.residentialAddress.houseNumber)}} {{(student.residentialAddress.additionHouseNumber)}} <br/>
                                        {{(student.residentialAddress.postalCode)}} {{(student.residentialAddress.city)}}
                                    </v-card-text>
                                </v-card>
                                <v-card falt outlined elevation class="ma-4" v-if="!student.postalAddress">
                                    <v-card-text>
                                        <span class="text-subtitle-2">
                                            Postadres
                                        </span><br/>
                                       Geen
                                    </v-card-text>
                                </v-card>
                                <v-card falt outlined elevation class="ma-4" v-if="student.postalAddress">
                                    <v-card-text>
                                        <span class="text-subtitle-2">
                                            Postadres
                                        </span><br/>
                                        {{(student.postalAddress.street)}} {{(student.postalAddress.houseNumber)}} {{(student.postalAddress.additionHouseNumber)}} <br/>
                                        {{(student.postalAddress.postalCode)}} {{(student.postalAddress.city)}}
                                    </v-card-text>
                                </v-card>
                                <v-card-text class="py-0">
                                     Lidmaatschap: {{(student.hasValidSubscription ? "Ja" : "Nee")}}
                                </v-card-text>
                                <v-card-text class="py-0">
                                     Geactiveerd: {{(student.isActivated ? "Ja" : "Nee")}} 
                                     <v-btn v-if="!student.isActivated"  color="primary" x-small @click="activeren(student.accountId)">ACTIVEREN</v-btn>
                                </v-card-text>
                                 <v-card-text class="py-0">
                                     <v-btn v-if="student.isActivated" color="primary" :loading='loading && accountToBeSetPassword == student.accountId' x-small @click="resetPassword(student.accountId)">RESET WACHTWOORD</v-btn>
                                </v-card-text>
                                <v-card-text v-if="student.publishedBookAlerts">
                                    <label class="font-weight-bold">Favorieten: {{student.publishedBookAlerts.length}}</label>
                                    <v-row class="pt-4">
                                        <v-col cols="12" md="4" v-for="(alert, index) in student.publishedBookAlerts" :key="index">
                                            <v-card class="pt-2 pl-2" min-height="100" max-height="400" outlined>
                                                {{alert.publishedBook.title}}, Druk: {{alert.publishedBook.edition}}, ISBN: {{alert.publishedBook.isbn}}
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-text class="pt-0" v-if="student.publishedBookAlerts">
                                    <label class="font-weight-bold">Aantal boeken: {{student.booksInPocession.length}}</label>
                                    <v-row class="pt-4">
                                        <v-col cols="12" md="4" v-for="(book, index) in student.booksInPocession" :key="index">
                                            <v-card class="pt-2 pl-2" min-height="100" max-height="400">
                                                {{book.publishedBook.title}}, Druk: {{book.publishedBook.edition}}, ISBN: {{book.publishedBook.isbn}}
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-text>
                                    <v-img :src="student.photo" alt="image" max-width="350"></v-img>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="1">
                </v-col>
                <v-col>
                    <v-pagination
                    v-if="totalPages>0"
                    style="padding-bottom: 3%; padding-top: 3%"
                    v-model="pageNumber"
                    :length="totalPages"
                    @input="next"
                    :total-visible="7">
                    </v-pagination>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import StudentService from '../../../services/StudentService';
import AccountService from '../../../services/AccountService';
import UserMenuCard from '../../../components/UserMenuCard.vue';
import { mapGetters } from 'vuex';

  export default {
    data () {
      return {
        students:[],
        loading: false,
        pageSize: 5,
        pageNumber: 1,
        totalPages:0,
        searchText:'',
        accountToBeSetPassword:'',
      }
    },
    async created(){
       //this.loadStudents();
    },
    methods:{
        ...mapGetters(['getAccessToken']),
       async loadStudents(){
           try{
                this.loading = true;
                let data = await StudentService.getStudents(this.getAccessToken(), this.pageNumber, this.pageSize);
                this.students = data.students;
                this.totalCount = data.totalCount;
                this.totalPages = Math.ceil(this.totalCount / this.pageSize);
           }catch{}

           this.loading = false;
        },
        async search(){
            try{
                this.loading = true;
                let data = await StudentService.findStudents(this.getAccessToken(), this.searchText, this.pageNumber, this.pageSize);
                this.students = data.students;
                this.totalCount = data.totalCount;
                this.totalPages = Math.ceil(this.totalCount / this.pageSize);
            }catch{}
            this.loading = false;
        },
        async next(p) {
            try{
               this.loading = true;
                let data = await StudentService.findStudents(this.getAccessToken(), this.searchText, this.pageNumber, this.pageSize);
                this.students = data.students;
                this.totalCount = data.totalCount;
                this.totalPages = Math.ceil(this.totalCount / this.pageSize);
            }catch{}
            this.loading = false;
        },
        async activeren(accountId){
             try{
               this.loading = true;
                await AccountService.activateUser(this.getAccessToken(), accountId);
                await this.search();
            }catch{}
            this.loading = false;
        },
        async resetPassword(accountId){
             try{
                this.loading = true;
                this.accountToBeSetPassword = accountId;
                await AccountService.setPassword(this.getAccessToken(), accountId);
                await this.search();
            }catch{}
            this.loading = false;
        },
    },
    components:{
        UserMenuCard
    },
  }
</script>