<template>
   <v-form>
        <v-container>
            <v-row justify="center" align="center" v-if="errorMessage || loading"> 
                <v-col cols="6" v-if="errorMessage">
                    <v-card-text>
                    {{errorMessage}}
                    </v-card-text>
                </v-col>
                <v-col class="my-8" cols="6">
                    <v-progress-circular
                    v-if="loading"
                    indeterminate
                    color="primary">
                    </v-progress-circular>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="mt-3">
                    <v-row>
                        <v-col>

                        </v-col>
                    </v-row>
                    <v-row v-if="bookOverview && bookOverview.booksData">
                        <v-card width="100%" class="ma-4" v-for="(bookData, index) in bookOverview.booksData" :key="index">
                            <v-card-title>{{bookData.title}}</v-card-title>
                            <v-card-subtitle>ISBN: {{bookData.isbn}}</v-card-subtitle>
                            <v-card-text>Aantal: {{bookData.quantity}}</v-card-text>
                        </v-card>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="3"></v-col>
                <v-col>
                    <v-row>
                        <v-pagination
                            v-if="totalPages >0"
                            style="padding-bottom: 3%; padding-top: 3%"
                            v-model="pageNumber"
                            :length="totalPages"
                            @input="load()"
                            :total-visible="7">
                        </v-pagination>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
   </v-form>
</template>

<script>
import BookService from '../../services/BookService';
import { mapGetters } from 'vuex';

export default {
    data () {
      return {
        loading: false,
        pageSize: 5,
        pageNumber: 1,
        totalPages:0,
        errorMessage: null,
        bookOverview: null, 
      }
    },
    async created(){
        await this.load()
    },
    methods:{
        ...mapGetters(['getAccessToken']),
        async load(){
            try{
                this.loading = true;
                let data = await BookService.getBooksOverview(this.getAccessToken(), this.pageNumber, this.pageSize);
                this.bookOverview = data.booksOverview;
                this.totalPages = Math.ceil(data.totalCount /this.pageSize);
                this.loading = false;
            }
            catch { 
                this.errorMessage = 'Er is iets mis gegaan.';
            }
        },
    },
    components:{
    },
}
</script>